<template>
  <div>
    <v-btn
      v-if="view === 'grid'"
      depressed
      width="150"
      height="150"
      class="asset-options ma-1"
      :href="href"
      :target="target"
      @click="click"
    >
      <div
        v-if="assetIcon"
        class="asset-icon"
        style="text-transform: none; white-space: initial; word-break: break-word;"
      >
        <v-icon x-large>
          {{ assetIcon }}
        </v-icon>
        <div class="text-center clamp-3">
          {{ item.meta.name }}
        </div>
      </div>
      <v-img
        v-else-if="itemType === 'image'"
        :src="item.url | mImage({ width: 150, height: 150, fit: 'contain', bg: '#FFFFFF' })"
        width="150"
        height="150"
        contain
        style="flex: initial;"
      >
        <template #placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            />
          </v-row>
        </template>
      </v-img>
      <video
        v-else-if="itemType === 'video'"
        :src="item.url"
        width="150"
        height="150"
        preload="metadata"
        @loadedmetadata="duration = $event.target.duration"
      />
      <div class="asset-options--overlay">
        <v-container fill-height pa-1 class="d-flex justify-end align-start">
          <v-menu
            transition="fade-transition"
            bottom
            left
          >
            <template #activator="{ on }">
              <v-btn
                fab
                x-small
                style="opacity: 0.8"
                v-on="on"
                @click.stop.prevent=""
              >
                <v-icon v-text="'mdi-dots-vertical'" />
              </v-btn>
            </template>
            <v-list dense>
              <v-subheader class="pl-5">
                {{ itemType | capitalizeFirstLetter }}
              </v-subheader>
              <v-btn
                icon
                style="position: absolute; right: 0; top: 0;"
                v-on="on"
              >
                <v-icon v-text="'mdi-close'" />
              </v-btn>
              <v-list-item
                v-if="itemType !== 'folder'"
                @click="$emit('references')"
              >
                <v-list-item-icon>
                  <v-icon v-text="'mdi-attachment'" />
                </v-list-item-icon>
                <v-list-item-title>
                  Show Documents That Reference This {{ itemType | capitalizeFirstLetter }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="$emit('delete')">
                <v-list-item-icon>
                  <v-icon v-text="'mdi-delete'" />
                </v-list-item-icon>
                <v-list-item-title>
                  Delete {{ itemType | capitalizeFirstLetter }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-container>
      </div>
    </v-btn>
    <v-list-item
      v-else
      dense
      three-line
      :href="href"
      :target="target"
      @click="click"
    >
      <v-list-item-icon class="my-1">
        <v-avatar tile size="60">
          <v-icon
            v-if="assetIcon"
            x-large
            v-text="assetIcon"
          />
          <v-img
            v-else-if="itemType === 'image'"
            :src="item.url | mImage({ width: 150, height: 150, fit: 'contain', bg: '#FFFFFF' })"
          >
            <template #placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey"
                />
              </v-row>
            </template>
          </v-img>
          <video
            v-else-if="itemType === 'video'"
            :src="item.url"
            preload="metadata"
            width="80"
            height="80"
            @loadedmetadata="duration = $event.target.duration"
          />
        </v-avatar>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title style="white-space: normal;">
          {{ item.meta.name }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="item.meta.type === 'file'" class="d-flex justify-space-between">
          <span v-if="itemType === 'video'">
            {{ duration | formatDuration }}
          </span>
          <span>{{ item.meta.size | formatBytes }}</span>
          <!-- <span>{{ item.meta.timeCreated | formatDate }}</span> -->
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-menu
          transition="fade-transition"
          bottom
          left
        >
          <template #activator="{ on }">
            <v-btn
              icon
              text
              small
              style="opacity: 0.8"
              v-on="on"
              @click.stop.prevent=""
            >
              <v-icon v-text="'mdi-dots-vertical'" />
            </v-btn>
          </template>
          <v-list dense>
            <v-subheader class="pl-5">
              {{ itemType | capitalizeFirstLetter }}
            </v-subheader>
            <v-btn
              icon
              large
              style="position: absolute; right: 0; top: 0;"
              v-on="on"
            >
              <v-icon v-text="'mdi-close'" />
            </v-btn>
            <v-list-item
              v-if="itemType !== 'folder'"
              @click="$emit('references')"
            >
              <v-list-item-icon>
                <v-icon v-text="'mdi-attachment'" />
              </v-list-item-icon>
              <v-list-item-title>
                Show Documents That Reference This {{ itemType | capitalizeFirstLetter }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="$emit('delete')"
            >
              <v-list-item-icon>
                <v-icon v-text="'mdi-delete'" />
              </v-list-item-icon>
              <v-list-item-title>
                Delete {{ itemType | capitalizeFirstLetter }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: 'AssetItem',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    view: {
      type: String,
      default: () => 'list'
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    on: null,
    duration: null
  }),
  computed: {
    itemType() {
      if (this.item.meta.type !== 'file') {
        return this.item.meta.type
      }
      const contentTypeArr = this.item.meta.contentType.split('/')
      return contentTypeArr[0] !== 'application' ? contentTypeArr[0] : contentTypeArr[1]
    },
    assetIcon() {
      if (['image', 'video'].includes(this.itemType)) {
        return null
      }
      switch (this.itemType) {
        case 'folder':
          return 'mdi-folder-outline'
        case 'pdf':
          return 'mdi-file-pdf'
        default:
          return 'mdi-file'
      }
    },
    href() {
      return this.itemType === 'folder' ? null : this.item.url
    },
    target() {
      return this.href ? '_blank' : null
    }
  },
  methods: {
    click(event) {
      if (this.href) {
        event.preventDefault()
      }
      this.$emit('click', event)
    }
  }
}
</script>

<style lang="sass">
  .asset-options
    position: relative
    .v-btn__content
      position: initial
    .asset-options--overlay
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
    .asset-icon
      position: absolute
      top: 0
      right: 0
      left: 0
      bottom: 0
      display: flex
      flex-direction: column
      .v-icon
        height: 50%
        margin-top: 12.5%
  .clamp-3
    display: -webkit-box
    -webkit-box-orient: vertical
    -webkit-line-clamp: 3
    overflow: hidden
</style>
