import Vue from 'vue'

const kebabToPascalCase = str => str
  .split('-')
  .map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
  .join('')

export const registerMComponents = (components, registerForms) => {
  Object.keys(components).forEach((componentType) => {
    components[componentType].forEach((componentName) => {
      const componentPascalCase = kebabToPascalCase(componentName)
      Vue.component(componentName, () => import(`@/components/${componentType}/${componentPascalCase}/${componentPascalCase}.vue`))
      registerForms && Vue.component(`${componentName}-form`, () => import(`@/components/${componentType}/${componentPascalCase}/${componentPascalCase}Form.vue`))
    })
  })
}

export const resolveDefaultOptions = (componentType, componentName) => {
  const componentPascalCase = kebabToPascalCase(componentName)
  return require(`../components/${componentType}s/${componentPascalCase}/options`).default
}
