import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'

export const state = () => ({
  uploads: {},
  fileIndex: 0,
  hidden: false
})

// getters
export const getters = {
}

// mutations
export const mutations = {
  ADD_FILES_TO_UPLOAD_QUEUE(state, { files, path }) {
    const status = 'queued'
    for (const file of files) {
      const id = uuidv4()
      const progress = 0
      Vue.set(state.uploads, id, { id, path, file, status, progress })
    }
    state.hidden = false
  },
  START_NEXT_UPLOAD(state) {
    if (Object.keys(state.uploads).length > state.fileIndex) {
      state.fileIndex += 1
    }
  },
  HIDE(state) {
    state.hidden = true
  }
}

// actions
export const actions = {
  selectFiles: ({ commit }, { files, path }) => {
    files = Object.values(files).filter(file => file && file.name)
    commit('ADD_FILES_TO_UPLOAD_QUEUE', { files, path })
  },
  uploadNext: ({ commit }) => {
    commit('START_NEXT_UPLOAD')
  },
  hide: ({ commit }) => {
    commit('HIDE')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
