export default {
  items: [
    {
      start: '2020-12-03 15:00',
      end: '2020-12-03 19:00',
      name: 'Event # 1',
      description: 'Event details'
    },
    {
      start: '2020-12-06 09:00',
      end: '2020-12-06 12:00',
      name: 'Some Meeting',
      description: 'Event details'
    },
    {
      start: '2020-12-13 15:00',
      end: '2020-12-13 19:00',
      name: 'Another Event',
      description: 'Event details'
    },
    {
      start: '2020-12-09 19:30',
      end: '2020-12-09 23:00',
      name: 'Workshop',
      description: 'Event details'
    },
    {
      start: '2020-12-23 15:00',
      end: '2020-12-23 19:00',
      name: 'Breakfast Session',
      description: 'Event details'
    }
  ]
}
