export default {
  /* Haus 4
  floors: [
    {
      id: 'erdgeschoss',
      title: 'Erdgeschoss',
      image: {
        src: 'https://firebasestorage.googleapis.com/v0/b/mammutos-7ad07.appspot.com/o/Z2NvcHZz1QdotNJWeV0DMUi2YSi2%2Fimages%2FHaus%204%20Erdgeschoss.svg?alt=media&token=a5c46ff1-0def-458e-8e78-f501262f09ac'
      }
    },
    {
      id: 'obergeschoss',
      title: 'Obergeschoss',
      image: {
        src: 'https://firebasestorage.googleapis.com/v0/b/mammutos-7ad07.appspot.com/o/Z2NvcHZz1QdotNJWeV0DMUi2YSi2%2Fimages%2FHaus%204%20Dachgeschoss.svg?alt=media&token=0a342089-eef1-4af6-b2fe-64e9679048df'
      }
    },
    {
      id: 'dachgeschoss',
      title: 'Dachgeschoss',
      image: {
        src: 'https://firebasestorage.googleapis.com/v0/b/mammutos-7ad07.appspot.com/o/Z2NvcHZz1QdotNJWeV0DMUi2YSi2%2Fimages%2FHaus%204%20Dachgeschoss.svg?alt=media&token=0a342089-eef1-4af6-b2fe-64e9679048df'
      }
    }
  ],
  rooms: [
    {
      room: 'Room 4-001',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M60,2.4 82.5,2.4 82.5,37.6, 60,37.6 Z'
    },
    {
      room: 'Room 4-003',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M2.7,2.4 18.2,2.4 18.2,20, 2.7,20, Z'
    },
    {
      room: 'Room 4-004',
      title: 'Company 2',
      floorId: 'erdgeschoss',
      d: 'M2.7,20 18.2,20 18.2,27 22.6,27 22.6,42.05 2.7,42.05 Z'
    },
    {
      room: 'Room 4-005',
      title: 'Company 2',
      floorId: 'erdgeschoss',
      d: 'M2.7,42.05 22.6,42.05 22.6,61.5, 2.7,61.5 Z'
    },
    {
      room: 'Room 4-006',
      title: 'Company 2',
      floorId: 'erdgeschoss',
      d: 'M2.7,61.5 22.5,61.5 22.5,78.3 20,82 2.7,82 Z'
    },
    {
      room: 'Room 4-007',
      title: 'Company 2',
      floorId: 'erdgeschoss',
      d: 'M2.7,82 18.5,82 18.5,97, 2.7,97 Z'
    },
    {
      room: 'Room 4-008',
      title: 'Company 2',
      floorId: 'erdgeschoss',
      d: 'M18.5,82 20,82 22.4,78.3 26,82.8 26,97 18.5,97 Z'
    },
    {
      room: 'Room 4-009',
      title: 'Company 2',
      floorId: 'erdgeschoss',
      d: 'M26,82.8 29.1,78.3 31.7,82 33.35,82 33.35,97 26,97 Z'
    },
    {
      room: 'Room 4-010',
      title: 'Company 2',
      floorId: 'erdgeschoss',
      d: 'M33.4,97 49.5,97 49.5,63.4 29.1,63.4 29.1,78.3 31.7,82 33.35,82 Z'
    },
    {
      room: 'Room 4-012',
      title: 'Company 2',
      floorId: 'erdgeschoss',
      d: 'M29.1,63.4 49.3,63.4 49.3,42 29.1,42 Z'
    },
    {
      room: 'Room 4-101',
      title: 'Company 1',
      floorId: 'obergeschoss',
      d: 'M60,2.4 82.5,2.4 82.5,37.6, 60,37.6 Z'
    },
    {
      room: 'Room 4-103',
      title: 'Company 1',
      floorId: 'obergeschoss',
      d: 'M2.7,2.4 18.2,2.4 18.2,20, 2.7,20, Z'
    },
    {
      room: 'Room 4-104',
      title: 'Company 2',
      floorId: 'obergeschoss',
      d: 'M2.7,20 18.2,20 18.2,27 22.6,27 22.6,42.05 2.7,42.05 Z'
    },
    {
      room: 'Room 4-105',
      title: 'Company 2',
      floorId: 'obergeschoss',
      d: 'M2.7,42.05 22.6,42.05 22.6,61.5, 2.7,61.5 Z'
    },
    {
      room: 'Room 4-106',
      title: 'Company 2',
      floorId: 'obergeschoss',
      d: 'M2.7,61.5 22.5,61.5 22.5,78.3 20,82 2.7,82 Z'
    },
    {
      room: 'Room 4-107',
      title: 'Company 2',
      floorId: 'obergeschoss',
      d: 'M2.7,82 18.5,82 18.5,97, 2.7,97 Z'
    },
    {
      room: 'Room 4-108',
      title: 'Company 2',
      floorId: 'obergeschoss',
      d: 'M18.5,82 20,82 22.4,78.3 26,82.8 26,97 18.5,97 Z'
    },
    {
      room: 'Room 4-109',
      title: 'Company 2',
      floorId: 'obergeschoss',
      d: 'M26,82.8 29.1,78.3 31.7,82 33.35,82 33.35,97 26,97 Z'
    },
    {
      room: 'Room 4-110',
      title: 'Company 2',
      floorId: 'obergeschoss',
      d: 'M33.4,97 49.5,97 49.5,82 33.35,82 Z'
    },
    {
      room: 'Room 4-111',
      title: 'Company 2',
      floorId: 'obergeschoss',
      d: 'M49.5,82 49.5,63.4 29.1,63.4 29.1,78.3 31.7,82 33.35,82 Z'
    },
    {
      room: 'Room 4-112',
      title: 'Company 2',
      floorId: 'obergeschoss',
      d: 'M29.1,63.4 49.3,63.4 49.3,42 29.1,42 Z'
    },
    {
      room: 'Room 4-201',
      title: 'Company 1',
      floorId: 'dachgeschoss',
      d: 'M60,2.4 82.5,2.4 82.5,37.6, 60,37.6 Z'
    },
    {
      room: 'Room 4-203',
      title: 'Company 1',
      floorId: 'dachgeschoss',
      d: 'M2.7,2.4 18.2,2.4 18.2,20, 2.7,20, Z'
    },
    {
      room: 'Room 4-204',
      title: 'Company 2',
      floorId: 'dachgeschoss',
      d: 'M2.7,20 18.2,20 18.2,27 22.6,27 22.6,42.05 2.7,42.05 Z'
    },
    {
      room: 'Room 4-205',
      title: 'Company 2',
      floorId: 'dachgeschoss',
      d: 'M2.7,42.05 22.6,42.05 22.6,61.5, 2.7,61.5 Z'
    },
    {
      room: 'Room 4-206',
      title: 'Company 2',
      floorId: 'dachgeschoss',
      d: 'M2.7,61.5 22.5,61.5 22.5,78.3 20,82 2.7,82 Z'
    },
    {
      room: 'Room 4-207',
      title: 'Company 2',
      floorId: 'dachgeschoss',
      d: 'M2.7,82 18.5,82 18.5,97, 2.7,97 Z'
    },
    {
      room: 'Room 4-208',
      title: 'Company 2',
      floorId: 'dachgeschoss',
      d: 'M18.5,82 20,82 22.4,78.3 26,82.8 26,97 18.5,97 Z'
    },
    {
      room: 'Room 4-209',
      title: 'Company 2',
      floorId: 'dachgeschoss',
      d: 'M26,82.8 29.1,78.3 31.7,82 33.35,82 33.35,97 26,97 Z'
    },
    {
      room: 'Room 4-110',
      title: 'Company 2',
      floorId: 'obergeschoss',
      d: 'M33.4,97 49.5,97 49.5,82 33.35,82 Z'
    },
    {
      room: 'Room 4-211',
      title: 'Company 2',
      floorId: 'dachgeschoss',
      d: 'M49.5,82 49.5,63.4 29.1,63.4 29.1,78.3 31.7,82 33.35,82 Z'
    },
    {
      room: 'Room 4-212',
      title: 'Company 2',
      floorId: 'dachgeschoss',
      d: 'M29.1,63.4 49.3,63.4 49.3,42 29.1,42 Z'
    }
  ]
  */

  // Haus 1
  floors: [
    {
      id: 'erdgeschoss',
      title: 'Erdgeschoss',
      image: {
        src: 'https://firebasestorage.googleapis.com/v0/b/mammutos-7ad07.appspot.com/o/storybook-demo-organization%2Fimages%2FHaus%201%20Erdgeschoss.png?alt=media&token=59cb5175-ee4e-49ce-9eb5-9c44e26be61f'
      }
    },
    {
      id: 'obergeschoss_1',
      title: 'Obergeschoss 1',
      image: {
        src: 'https://firebasestorage.googleapis.com/v0/b/mammutos-7ad07.appspot.com/o/storybook-demo-organization%2Fimages%2FHaus%201%20Obergeschoss%201.png?alt=media&token=5bbf2e2d-8f33-4299-9513-6ca26dee3e43'
      }
    }
  ],
  // erdgeschoss
  rooms: [
    {
      room: 'Room 001',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M67,33.5 74.3,33.5 74.3,45.2 71,45.2 71,49.5 67,49.5 Z'
    },
    {
      room: 'Room 002',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M74.3,33.5 81.75,33.5 81.75,45.2 74.3,45.2 Z'
    },
    {
      room: 'Room 003',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M81.75,33.5 88.75,33.5 88.75,45.2 81.75,45.2 Z'
    },
    {
      room: 'Room 004',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M88.75,28.9 92.4,28.9 92.4,31 96.2,31 96.2,45.2 88.75,45.2 Z'
    },
    {
      room: 'Room 005',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M88.75,9.7 96.2,9.7 96.2,24.4 89.8,24.4 89.8,22.8 88.75,22.8 Z'
    },
    {
      room: 'Room 006',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M81.7,9.7 88.75,9.7 88.75,19.5 87.45,19.5 84.5,28.9 81.7,28.9 Z'
    },
    {
      room: 'Room 007',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M77.4,9.7 81.7,9.7 81.7,28.9 76.45,28.9 76.45,19.5 77.4,19.5 Z'
    },
    {
      room: 'Room 008',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M71.3,9.7 77.4,9.7 77.4,19.5 76.45,19.5 76.45,28.9 72.2,28.9 72.2,19.3 71.3,19.3 Z'
    },
    {
      room: 'Room 009',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M67.3,9.7 71.3,9.7 71.3,19.3 72.2,19.3 72.2,28.9 67.3,28.9 Z'
    },
    {
      room: 'Room 010',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M60.2,9.7 67.3,9.7 67.3,28.9 60.2,28.9 Z'
    },
    {
      room: 'Room 014',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M46.3,31.5 53.2,31.5 53.2,45.4 46.3,45.4 Z'
    },
    {
      room: 'Room 015',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M38.6,35.4, 46.3,35.4 46.3,45.4 38.6,45.4 Z'
    },
    {
      room: 'Room 024 A',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M19.3,9.7 31.4,9.7 31.4,15.5 19.3,15.5 Z'
    },
    {
      room: 'Room 024',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M25.9,15.5 31.4,15.5 31.4,28.3 25.9,28.3 Z'
    },
    {
      room: 'Room 023',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M22.6,34.8 31.4,34.8 31.4,45.7 28.8,49.8, 22.6,49.8 Z'
    },
    {
      room: 'Room 022',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M22.6,49.8 28.8,49.8 28.8,65.9 22.6,65.9 Z'
    },
    {
      room: 'Event 2',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M19.4,67.2 19.4,90 4.6,90 Q4,76 6.3,63.4 Z'
    },
    {
      room: 'Event 3',
      title: 'Company 1',
      floorId: 'erdgeschoss',
      d: 'M19.4,48.1 19.4,67.2 6.3,63.4 Q7.5,55 10.1,45.5 Z'
    },
    {
      room: 'Room 101',
      title: 'Company 1',
      floorId: 'obergeschoss_1',
      d: 'M67.3,33.5 74.3,33.5 74.3,46 71,46 71,49.5 67.3,49.5 Z'
    },
    {
      room: 'Room 102',
      title: 'Company 1',
      floorId: 'obergeschoss_1',
      d: 'M74.3,33.5 85,33.5 85,28.3 89.8,28.3 89.8,29.3 92.4,29.3 92.4,31.2 96.3,31.2 96.3,46 74.3,46 Z'
    },
    {
      room: 'Room 103',
      title: 'Company 1',
      floorId: 'obergeschoss_1',
      d: 'M81.7,9.5 96.3,9.5 96.3,24.7 89.8,24.7 89.8,28.5 81.7,28.5 Z'
    },
    {
      room: 'Room 104',
      title: 'Company 1',
      floorId: 'obergeschoss_1',
      d: 'M81.7,9.5 81.7,28.5 67.3,28.5 67.3,9.5 Z'
    },
    {
      room: 'Room 105',
      title: 'Company 1',
      floorId: 'obergeschoss_1',
      d: 'M67.3,9.5 67.3,28.5 53.1,28.5 53.1,9.5 Z'
    },
    {
      room: 'Room 106',
      title: 'Company 1',
      floorId: 'obergeschoss_1',
      d: 'M53.1,9.5 53.1,28.5 46.2,28.5 46.2,9.5 Z'
    },
    {
      room: 'Room 107',
      title: 'Company 1',
      floorId: 'obergeschoss_1',
      d: 'M46.2,9.5 46.2,28.5 34.5,28.5 34.5,9.5 Z'
    },
    {
      room: 'Room 109',
      title: 'Company 1',
      floorId: 'obergeschoss_1',
      d: 'M22.2,62.2 28.6,62.2 28.6,77 22.2,77 Z'
    },
    {
      room: 'Room 110',
      title: 'Company 1',
      floorId: 'obergeschoss_1',
      d: 'M22.2,50.2 28.6,50.2 28.6,62.2 22.2,62.2 Z'
    },
    {
      room: 'Room 111',
      title: 'Company 1',
      floorId: 'obergeschoss_1',
      d: 'M31.3,34.2 46.2,34.2 46.2,45.7 31.3,45.7 Z'
    },
    {
      room: 'Room 111 B',
      title: 'Company 1',
      floorId: 'obergeschoss_1',
      d: 'M46.2,34.2 50.2,34.2 50.2,45.7 46.2,45.7 Z'
    }
  ]
}
