<template>
  <v-sheet
    color="primary"
    class="layout-container"
    tile
  >
    <v-overlay
      v-if="display && !planStatus"
      absolute
    >
      <v-container class="d-flex fill-height justify-center align-center">
        <v-card max-width="600">
          <v-card-title class="justify-center display-1">
            <v-icon color="warning" large left>
              mdi-exclamation
            </v-icon>
            Insufficient Plan
          </v-card-title>
          <v-card-title class="text-underline body-2 grey--text pb-0 justify-center" style="text-decoration: underline;">
            Display Name
          </v-card-title>
          <v-card-title class="font-weight-bold justify-center text-center pt-0 pb-3" style="word-break: break-word;">
            {{ display.name }}
          </v-card-title>
          <v-card-title class="justify-center headline text-center pb-3" style="word-break: break-word;">
            Please configure the plan of your display to resume using this layout.
          </v-card-title>
        </v-card>
      </v-container>
    </v-overlay>
    <!-- widgets -->
    <m-layout-grid
      v-if="layout.widgets && layout.widgets[0] && layout.widgets[0].grid"
      :layout-widgets="layout.widgets"
      :aspect-ratio="layout.resolution | aspectRatio"
      @open-page="openPage($event)"
    />
    <div
      v-else
      style="height: 100vh;"
      class="d-flex flex-column primary"
    >
      <template v-for="(layoutWidget, i) in sortedLayoutWidgets">
        <v-spacer
          v-if="sortedLayoutWidgets.length - 1 === i"
          :key="i"
        />
        <component
          :is="layoutWidget.widget.component"
          v-if="layoutWidget.options"
          :key="layoutWidget | dateKey"
          v-bind="layoutWidget.options"
          @open-page="openPage($event)"
        />
      </template>
    </div>
    <v-container
      v-if="display && planStatus === 'free'"
      fluid
      :class="`mammut-os-watermark watermark-location--${watermarkLocationY} watermark-location--${watermarkLocationX}`"
    >
      <v-img
        class="flex-grow-0"
        src="/watermark_white.svg"
        width="25rem"
        contain
        :position="watermarkLocationX"
      />
    </v-container>
    <!-- pages -->
    <v-dialog
      v-model="pageDialog"
      content-class="page-dialog"
      :hide-overlay="false"
      persistent
      scrollable
      :fullscreen="pageDialogFullscreen"
      transition="dialog-bottom-transition"
      :width="pageDialogFullscreen ? null : '90vw'"
    >
      <v-card
        flat
        height="100%"
      >
        <v-card-title
          v-if="layoutPage && layoutPage.page && pageDialogAnimationComplete"
          class="px-2 py-1 page-title"
        >
          <v-btn
            v-if="pageCanGoBack"
            fab
            text
            @click="pageBack"
          >
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
          <span class="font-weight-bold">
            {{ pageTitle }}
          </span>
          <v-spacer />
          <v-btn
            v-if="layoutPage.page.fullscreenable"
            fab
            text
            @click="pageDialogFullscreen = !pageDialogFullscreen"
          >
            <v-icon>mdi-fullscreen</v-icon>
          </v-btn>
          <v-btn
            fab
            text
            @click="pageDialog = false"
          >
            <v-icon v-text="'mdi-close'" />
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text
          v-if="layoutPage && layoutPage.page"
          class="pa-2 relative"
        >
          <component
            :is="layoutPage.page.component"
            v-if="pageDialogAnimationComplete"
            :key="layoutPage | dateKey"
            ref="page"
            v-bind="layoutPage.options"
            :layout-id="layout.id"
            :page-no="pageNo"
            @title-change="pageTitle = $event"
            @page-backable="pageCanGoBack = $event"
            @loaded="pageLoaded = true"
            @loading="pageLoaded = false"
            @close="pageDialog = false"
            @numpages="numPages = $event"
          />
          <v-overlay
            :value="!pageLoaded"
            opacity="0.7"
            color="white"
            absolute
          >
            <v-layout
              column
              align-center
              justify-center
            >
              <v-progress-circular
                indeterminate
                size="64"
                color="primary"
              />
              <!-- <v-subheader class="title mt-1 primary--text">
                Loading...
              </v-subheader> -->
            </v-layout>
          </v-overlay>
        </v-card-text>
        <v-card-actions
          v-if="numPages > 1"
          class="px-5 pb-3"
        >
          <v-btn
            text
            :disabled="pageNo === 1"
            @click="pageNo -= 1"
          >
            Vorherige Seite
          </v-btn>
          <v-spacer />
          <v-chip
            disabled
          >
            {{ pageNo }}/{{ numPages }}
          </v-chip>
          <v-spacer />
          <v-btn
            text
            :disabled="pageNo >= numPages"
            @click="pageNo += 1"
          >
            Nächste Seite
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- plugins -->
    <template
      v-for="layoutPlugin in layout.plugins"
    >
      <component
        :is="layoutPlugin.plugin.component"
        v-if="layoutPlugin.options"
        :key="layoutPlugin | dateKey"
        v-bind="layoutPlugin.options"
      />
    </template>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'
import MLayoutGrid from '@/components/core/MLayoutGrid'
export default {
  name: 'Layout',
  components: {
    MLayoutGrid
  },
  props: {
    layoutId: {
      type: String,
      required: true
    },
    planStatus: {
      type: [String, Boolean],
      default: () => true
    }
  },
  data: () => ({
    pageDialog: false,
    pageDialogAnimationComplete: false,
    pageLoaded: false,
    pageDialogFullscreen: false,
    pageTitle: null,
    pageCanGoBack: false,
    layoutPageId: null,
    numPages: 1,
    pageNo: 1,
    watermarkLocationY: 'bottom',
    watermarkLocationX: 'right'
  }),
  computed: {
    layout() {
      return this.$store.getters.layout || {}
    },
    sortedLayoutWidgets() {
      return (this.layout.widgets || []).sort(this.sortLayoutWidget)
    },
    layoutPage() {
      return this.$store.getters.layoutPageById(this.layoutPageId)
    },
    ...mapGetters(['display', 'layoutTheme'])
  },
  watch: {
    layoutTheme: {
      handler(newTheme) {
        if (newTheme) {
          this.$vuetify.theme.themes.light = newTheme
        }
      },
      immediate: true,
      deep: true
    },
    pageDialog(val) {
      if (!val) {
        this.layoutPageId = false
        this.pageDialogAnimationComplete = false
        this.pageLoaded = false
        this.pageTitle = null
        this.pageCanGoBack = false
        this.numPages = 1
        this.pageNo = 1
      }
    },
    'display.watermarkLocation': {
      handler(watermarkLocation) {
        if (!watermarkLocation) {
          return
        }
        const arr = watermarkLocation.split('-')
        if (arr.length !== 2) {
          return
        }
        this.watermarkLocationY = arr[0]
        this.watermarkLocationX = arr[1]
      },
      immediate: true
    }
  },
  async beforeMount() {
    await this.$store.dispatch('bindLayout', this.layoutId)
  },
  methods: {
    sortLayoutWidget(a, b) {
      if (a.gridOptions.y === b.gridOptions.y) {
        return a.gridOptions.x - b.gridOptions.x
      }
      return a.gridOptions.y - b.gridOptions.y
    },
    openPage(pageId) {
      this.layoutPageId = pageId
      if (this.layoutPage) {
        this.pageDialog = true
        this.pageTitle = this.layoutPage.name
        setTimeout(() => {
          this.pageDialogAnimationComplete = true
        }, 1000)
      } else {
        alert('Page to be opened not found')
      }
    },
    pageBack() {
      this.$refs.page.back()
    }
  }
}
</script>

<style lang="sass">
  .layout-container
    height: 100vh
    width: 100vw
  .relative
    position: relative
  .page-title
    position: relative
    .v-btn
      z-index: 5
    span
      position: absolute
      left: 0
      width: 100%
      text-align: center
  .page-dialog:not(.v-dialog--fullscreen)
    height: 70vh
  .v-btn--fab.v-size--default
    height: 5vmin
    width: 5vmin
    .v-icon
      font-size: 3vmin
  .mammut-os-watermark
    position: fixed
    z-index: 10
    width: 100%
    display: flex
    &.watermark-location--top
      padding-bottom: 50px
      top: 0
      background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0)), color-stop(0.5, #0000001a))
    &.watermark-location--bottom
      padding-top: 100px
      bottom: 0
      background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0)), color-stop(0.5, #0000001a))
      align-items: flex-end
    &.watermark-location--right
      justify-content: flex-end
    &.watermark-location--left
      justify-content: flex-start
</style>
