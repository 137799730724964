<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    const { version } = require('@/../package.json')
    const html = document.getElementsByTagName('html')[0]
    html.className = html.className.replace(/\bloading\b/, `loaded-version-${version}`)
  }
}
</script>

<style lang="sass">
html
  overflow-y: hidden!important
  font-size: 1.5vmin
</style>
