<template>
  <field-card label="Container Options">
    <m-color-input
      v-model="value.backgroundColor"
      label="Background Color"
    />
  </field-card>
</template>

<script>
export default {
  name: 'MWidgetContainerForm',
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
