<template>
  <div class="d-flex flex-column align-center">
    <div
      v-if="showDate"
      class="text-no-wrap title"
      :style="{ color: textHex }"
    >
      {{ dateString }}
    </div>
    <div
      class="font-weight-bold text-no-wrap display-2"
      :style="{ color: textHex }"
    >
      {{ timeString }}
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'MClockWidget',
  props: {
    textColor: {
      type: String,
      default: () => 'white'
    },
    showSeconds: {
      type: Boolean,
      default: () => false
    },
    showDate: {
      type: Boolean,
      default: () => false
    },
    dateFormat: {
      type: String,
      default: () => 'D MMMM YYYY'
    },
    timeFormat: {
      type: String,
      default: () => 'HH:mm'
    }
  },
  data: () => ({
    date: new Date()
  }),
  computed: {
    dateString() {
      return moment(this.date).format(this.dateFormat)
    },
    timeString() {
      return moment(this.date).format(this.timeFormat)
    },
    textHex() {
      if (this.textColor.startsWith('#')) {
        return this.textColor
      }
      return this.$vuetify.theme.themes.light[this.textColor]
    }
  },
  mounted() {
    setInterval(this.getTime, 1000)
  },
  methods: {
    getTime() {
      this.date = new Date()
    }
  }
}
</script>
