import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { auth, getAsyncCurrentUser, db } from '@/plugins/firebase'
import { getPlanStatus } from '@/utils/get_plan_status'
import { registerMComponents } from '@/utils/resolve_widgets'
import firebaseStorageUploader from '@/store/modules/firebase_storage_uploader'

Vue.use(Vuex)
// state
export const state = () => ({
  user: auth.currentUser,
  userSettings: null,
  organizations: [],
  organizationId: undefined,
  displays: [],
  screens: [],
  layouts: [],
  tags: [],
  layoutId: undefined,
  layoutWidgets: [],
  layoutPages: [],
  layoutPlugins: [],
  MWidgets: [],
  MPlugins: [],
  MPages: [],
  previewDrawer: false,
  subscription: null,
  snackbars: []
})

// getters
export const getters = {
  user: state => state.user,
  organization: (state, getters) => {
    const organization = state.organizations.length === 1 ? state.organizations[0] : getters.organizationById(state.organizationId)
    if (!organization) {
      return organization
    }
    // hideFreeTrial is configured manually on firebase
    if (organization.freeTrialStartAt && !organization.hideFreeTrial) {
      const date = organization.freeTrialStartAt.toDate()
      const daysSinceTrialStarted = parseInt(moment.duration(moment(Date.now()) - moment(date, 'days')).asDays())
      organization.remainingTrialDays = 30 - daysSinceTrialStarted
      organization.isUsingTrialDisplay = !!getters.displays.find(d => d.plan === 'trial')
    }
    const usedPlans = { trial: 0, free: 0, advertising: 0, view: 0, touch: 0 }
    for (const plan of getters.displays.map(x => x.plan)) {
      usedPlans[plan || 'free']++
    }
    organization.usedPlans = usedPlans
    return organization
  },
  organizationById: state => id => state.organizations.find(organization => organization.id === id),
  subscription: (state, getters) => {
    const subscription = { advertising: 0, view: 0, touch: 0 }
    if (state.subscription && state.subscription.items) {
      for (const item of state.subscription.items.data) {
        const plan = item.plan.nickname.split(' ')[0].toLowerCase()
        subscription[plan] = item.quantity
      }
    }
    for (const plan of Object.keys(getters.organization.manualPlans || {})) {
      subscription[plan] += getters.organization.manualPlans[plan]
    }
    return subscription
  },
  requiredPlans: (state, getters) => {
    const required = { advertising: 0, view: 0, touch: 0 }
    for (const display of getters.displays.filter(x => !x.planStatus)) {
      display.layout && required[display.layout.minPlan]++
    }
    return required
  },
  displays: (state, getters) => state.displays.map((display) => {
    if (display.layoutId) {
      display.layout = getters.layouts.find(layout => layout.id === display.layoutId)
      if (display.layout) {
        display.planStatus = getPlanStatus({ minPlan: display.layout.minPlan, plan: display.plan })
      }
    }
    return display
  }),
  displayById: (state, getters) => id => getters.displays.find(display => display.id === id),
  layout: (state, getters) => getters.layoutById(state.layoutId),
  layouts: (state, getters) => state.layouts.map((layout) => {
    layout.widgets = getters.layoutWidgets.filter(layoutWidget => layoutWidget.layoutId === layout.id)
    layout.pages = getters.layoutPages.filter(layoutPage => layoutPage.layoutId === layout.id)
    layout.plugins = getters.layoutPlugins.filter(layoutPlugin => layoutPlugin.layoutId === layout.id)
    layout.displays = state.displays.filter(display => display.layoutId === layout.id)
    return layout
  }),
  layoutById: (state, getters) => id => getters.layouts.find(layout => layout.id === id),
  layoutWidgets: state => state.layoutWidgets.map((layoutWidget) => {
    layoutWidget.widget = state.MWidgets.find(widget => widget.id === layoutWidget.widgetId)
    return layoutWidget
  }),
  layoutWidgetById: (state, getters) => id => getters.layoutWidgets.find(layoutWidget => layoutWidget.id === id),
  layoutPages: state => state.layoutPages.map((layoutPage) => {
    layoutPage.page = state.MPages.find(page => page.id === layoutPage.pageId)
    return layoutPage
  }),
  layoutPageById: (state, getters) => id => getters.layoutPages.find(layoutPage => layoutPage.id === id),
  layoutPlugins: state => state.layoutPlugins.map((layoutPlugin) => {
    layoutPlugin.plugin = state.MPlugins.find(plugin => plugin.id === layoutPlugin.pluginId)
    return layoutPlugin
  }),
  layoutPluginById: (state, getters) => id => getters.layoutPlugins.find(layoutPlugin => layoutPlugin.id === id),
  layoutTheme: state => state.layout ? state.layout.theme : null,
  MWidgets: state => state.MWidgets,
  MWidgetById: state => id => state.MWidgets.find(widget => widget.id === id),
  MPages: state => state.MPages,
  MPageById: state => id => state.MPages.find(page => page.id === id),
  MPlugins: state => state.MPlugins,
  MPluginById: state => id => state.MPlugins.find(plugin => plugin.id === id),
  referencesBySrc: state => (src) => {
    if (!src) { return }
    const components = [...state.layoutWidgets, ...state.layoutPages, ...state.layoutPlugins]
    try {
      return components.filter(comp => nestedSearch(comp, src))
    } catch (error) {
      console.error(error)
      return []
    }
  }
}

// TODO: Fix search error
const nestedSearch = (value, query) => {
  if (Array.isArray(value)) {
    return value.map(v => nestedSearch(v, query)).includes(true)
  } else if (value && typeof value === 'object') {
    return Object.keys(value).map(v => nestedSearch(value[v], query)).includes(true)
  }
  return String(value).includes(query)
}

// mutations
export const mutations = {
  SET_USER(state, user) {
    const currentUser = user || auth.currentUser
    if (!currentUser) {
      state.user = null
    } else {
      const { uid, email, emailVerified, displayName, photoURL, phoneNumber, metadata } = currentUser
      state.user = { uid, email, emailVerified, displayName, photoURL, phoneNumber, metadata }
    }
  },
  SET_ORGANIZATION_ID(state, organizationId) {
    state.organizationId = organizationId || undefined
    if (organizationId) {
      window.localStorage.setItem('organizationId', organizationId)
    } else {
      window.localStorage.removeItem('organizationId')
    }
  },
  SET_LAYOUT_ID(state, layoutId) {
    state.layoutId = layoutId || undefined
  },
  SET_SUBSCRIPTION(state, data) {
    state.subscription = data
  },
  NEW_SNACKBAR(state, snackbar) {
    snackbar.id = snackbar.id || uuidv4()
    state.snackbars.push(snackbar)
  },
  DISMISS_SNACKBAR(state, snackbarId) {
    state.snackbars = state.snackbars.filter(x => x.id !== snackbarId)
  },
  ...vuexfireMutations
}

// actions
export const actions = {
  async setUserAsync({ commit }) {
    const user = await getAsyncCurrentUser()
    commit('SET_USER', user)
    return user
  },
  setUser: ({ commit }, user) => {
    commit('SET_USER', user)
  },
  setLayoutId: ({ commit }, layoutId) => {
    commit('SET_LAYOUT_ID', layoutId)
  },
  bindMComponents: firestoreAction(async({ state, bindFirestoreRef }) => {
    await bindFirestoreRef('MWidgets', db.collection('m-widgets'))
    await bindFirestoreRef('MPages', db.collection('m-pages'))
    await bindFirestoreRef('MPlugins', db.collection('m-plugins'))
    await bindFirestoreRef('screens', db.collection('screens'))
    registerMComponents({
      widgets: [...state.MWidgets.map(x => x.component)],
      pages: [...state.MPages.map(x => x.component)],
      plugins: [...state.MPlugins.map(x => x.component)]
    }, true)
  }),
  bindUserDocuments: firestoreAction(async({ state, bindFirestoreRef }) => {
    await bindFirestoreRef('userSettings', db.collection('user-settings').doc(state.user.uid))
    await bindFirestoreRef('organizations', db.collection('organizations').where('memberUserIds', 'array-contains', state.user.uid))
  }),
  setOrganizationId: firestoreAction(({ getters, commit, bindFirestoreRef, unbindFirestoreRef }, organizationId) => {
    commit('SET_ORGANIZATION_ID', organizationId)
    if (organizationId) {
      bindFirestoreRef('displays', db.collection('displays').where('organizationId', '==', organizationId))
      bindFirestoreRef('layouts', db.collection('layouts').where('organizationId', '==', organizationId))
      bindFirestoreRef('tags', db.collection(`organizations/${organizationId}/tags`))
      bindFirestoreRef('layoutWidgets', db.collection('layout-widgets').where('organizationId', '==', organizationId))
      bindFirestoreRef('layoutPages', db.collection('layout-pages').where('organizationId', '==', organizationId))
      bindFirestoreRef('layoutPlugins', db.collection('layout-plugins').where('organizationId', '==', organizationId))
    } else {
      unbindFirestoreRef('displays')
      unbindFirestoreRef('layouts')
      unbindFirestoreRef('tags')
      unbindFirestoreRef('layoutWidgets')
      unbindFirestoreRef('layoutPages')
      unbindFirestoreRef('layoutPlugins')
    }
  }),
  setSubscription({ commit }, data) {
    commit('SET_SUBSCRIPTION', data)
  },
  newSnackbar: ({ commit }, snackbar) => {
    // eslint-disable-next-line
    snackbar.error && console.warn('error', snackbar.error)
    commit('NEW_SNACKBAR', snackbar)
  },
  dismissSnackbar: ({ commit }, snackbarId) => {
    commit('DISMISS_SNACKBAR', snackbarId)
  }
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    uploader: firebaseStorageUploader
  }
})
