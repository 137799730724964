<template>
  <grid-layout
    :layout.sync="gridLayout"
    :col-num="1"
    :row-height="66"
    @layout-updated="layoutUpdated"
  >
    <grid-item
      v-for="gItem in gridLayout"
      :key="gItem.i"
      :i="gItem.i"
      :x="gItem.x"
      :y="gItem.y"
      :w="gItem.w"
      :h="gItem.h"
      :is-resizable="false"
      drag-allow-from=".drag"
    >
      <v-card outlined class="d-flex">
        <v-avatar class="drag" size="66">
          <v-icon>
            mdi-drag-variant
          </v-icon>
        </v-avatar>
        <v-list-item
          dense
          class="pl-1 align-center"
          style="max-width: calc(100% - 66px);"
          @click="$emit('edit-item', gItem.item)"
        >
          <v-list-item-icon v-if="itemIcon">
            <v-avatar tile>
              <slot name="item.icon" :item="gItem.item">
                <v-icon
                  v-text="gItem.item[itemIcon]"
                />
              </slot>
            </v-avatar>
          </v-list-item-icon>
          <v-list-item-icon v-if="itemPhoto">
            <v-avatar tile>
              <slot name="item.photo" :item="gItem.item">
                <v-img
                  :src="gItem.item[itemPhoto] | mImage"
                  contain
                />
              </slot>
            </v-avatar>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <slot name="item.title" :item="gItem.item">
                <!-- object assign is helping keep item contents synced, dont change to string -->
                <!-- {{ (typeof gItem.item === 'string' && !itemTitle) ? gItem.item : gItem.item[itemTitle] }} -->
                {{ gItem.item[itemTitle] }}
              </slot>
            </v-list-item-title>
            <v-list-item-subtitle>
              <slot name="item.subtitle" :item="gItem.item">
                {{ gItem.item[itemSubtitle] }}
              </slot>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              @click.stop.prevent="deleteItem(gItem)"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </grid-item>
  </grid-layout>
</template>

<script>
import { GridLayout, GridItem } from 'vue-grid-layout'
import { v1 as uuidv1 } from 'uuid'
export default {
  components: {
    GridLayout,
    GridItem
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    itemIcon: {
      type: String,
      default: () => ''
    },
    itemPhoto: {
      type: String,
      default: () => ''
    },
    itemTitle: {
      type: String,
      default: () => 'title'
    },
    itemSubtitle: {
      type: String,
      default: () => 'subtitle'
    },
    beforeDelete: {
      type: Function,
      default: () => true
    }
  },
  data() {
    return {
      gridLayout: []
    }
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        if (!oldValue || newValue.length !== oldValue.length) {
          const newLayout = []
          newValue.forEach((item, index) => {
            newLayout.push({
              item,
              i: uuidv1(),
              y: index,
              x: 0,
              w: 1,
              h: 1
            })
          })
          this.gridLayout = newLayout
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    layoutUpdated(newLayout) {
      const value = []
      for (const { y, item } of newLayout) {
        value[y] = item
      }
      this.$emit('input', Object.values(value))
    },
    deleteItem(gItem) {
      console.log(this.beforeDelete)
      if (!this.beforeDelete || this.beforeDelete(gItem.item)) {
        if (confirm('Are you sure you want to delete this item?')) {
          const newValue = this.gridLayout.filter(x => x !== gItem).map(x => x.item)
          this.$emit('input', newValue)
        }
      }
    }
  }
}
</script>
