export default {
  accessToken: null,
  backgroundColor: 'primary',
  titleColor: 'white',
  feedTitle: 'Example Title',
  feedType: 'hashtag',
  hashtag: {
    tag: 'MüngstenerBrücke',
    type: 'top_media'
  },
  businessPages: []
}
