<template>
  <v-card flat>
    <v-toolbar dense flat style="contain: initial;">
      <v-btn
        icon
        tile
        :disabled="!relativePath || relativePath === '/'"
        @click="goUpOneDirectory"
      >
        <v-icon>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-icon left>
        mdi-folder-outline
      </v-icon>
      home{{ relativePath }}
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          text
          tile
          @click.stop="$refs.uploader.click()"
        >
          <v-icon left v-text="'mdi-cloud-upload-outline'" />
          Upload
          <input
            ref="uploader"
            class="upload-input"
            type="file"
            multiple
            @change="startUpload"
          >
        </v-btn>
        <v-btn
          text
          tile
          @click="view = view === 'grid' ? 'list' : 'grid'"
        >
          <v-icon left>
            mdi-{{ view === 'grid' ? 'format-list-bulleted' : 'view-grid' }}
          </v-icon>
          {{ view === 'grid' ? 'List' : 'Grid' }}
        </v-btn>
        <v-btn
          icon
          tile
          @click="newFolderDialog = true"
        >
          <v-icon>
            mdi-folder-plus-outline
          </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text :class="view === 'grid' ? 'd-flex flex-wrap' : ''">
      <asset-item
        v-for="item in directoryContents"
        :key="item.url"
        :item="item"
        :view="view"
        @click="clickItem(item)"
        @delete="deleteItem(item)"
        @references="showReferences(item.url)"
      />
    </v-card-text>
    <v-card-actions v-if="nextPageToken || loading" class="justify-center">
      <v-btn
        v-if="nextPageToken"
        text
        @click="loadNextPage"
      >
        Load More
      </v-btn>
      <v-progress-circular v-if="loading" indeterminate />
      </v-progress>
    </v-card-actions>
    <v-dialog
      v-model="newFolderDialog"
      max-width="300px"
    >
      <validation-observer
        ref="newFolderForm"
        v-slot="{ valid }"
        tag="form"
        @submit.prevent="createFolder"
      >
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>
              New Folder
            </v-toolbar-title>
            <v-spacer />
            <v-btn
              icon
              @click="newFolderName = '';newFolderDialog = false"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <validation-provider
              v-slot="{ errors }"
              rules="required|folder_unique"
              name="Folder Name"
            >
              <v-text-field
                v-model="newFolderName"
                outlined
                autofocus
                label="Folder Name"
                class="display-1"
                :error-messages="errors"
              />
            </validation-provider>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              outlined
              color="primary"
              type="submit"
              :loading="creatingFolder"
              :disabled="!valid || creatingFolder"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>
    <v-dialog
      v-model="referencesDialog"
      max-width="300px"
    >
      <v-card>
        <v-card-title class="justify-space-between">
          References
          <v-btn
            fab
            text
            class="mt-n2 mr-n4"
            @click="referencesDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-list>
          <v-list-item
            v-for="reference in references"
            :key="reference.id"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ reference.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ getMComponent(reference).name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="!references.length">
            <v-list-item-content>
              <v-list-item-title>
                No items are currently using this asset.
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { extend } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { storageRef, storage } from '../../plugins/firebase'
export default {
  name: 'AssetGallery',
  props: {
    path: {
      type: String,
      default: () => ''
    },
    router: {
      type: Boolean,
      default: () => false
    },
    itemsPerPage: {
      type: Number,
      default: () => 100
    },
    assetType: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      view: window.localStorage.getItem('asset-gallery-view'),
      relativePath: undefined,
      loading: false,
      subdirectories: [],
      assets: [],
      creatingFolder: false,
      uploader: false,
      deleting: null,
      selectedAssetUrl: null,
      newFolderName: '',
      newFolderDialog: false,
      referencesDialog: false,
      uploadFileCounter: 0,
      nextPageToken: null
    }
  },
  computed: {
    ...mapGetters(['organization']),
    ...mapState('uploader', {
      uploadIndex: state => state.fileIndex
    }),
    homeDirectory() {
      return this.organization ? this.organization.id : null
    },
    fullPath() {
      return this.homeDirectory && this.relativePath ? `${this.homeDirectory}/${this.relativePath}` : this.homeDirectory
    },
    storageRef() {
      return this.fullPath ? storageRef.child(this.fullPath) : null
    },
    selectedAssetRef() {
      return typeof this.selectedAssetUrl === 'string' ? storage.refFromURL(this.selectedAssetUrl) : null
    },
    directoryContents() {
      return this.subdirectories.concat(this.assets)
    },
    references() {
      return this.selectedAssetRef ? this.$store.getters.referencesBySrc(encodeURIComponent(this.selectedAssetRef.fullPath)) : []
    }
  },
  watch: {
    path: {
      handler(path) {
        this.relativePath = path
      },
      immediate: true
    },
    relativePath: {
      handler() {
        this.initRelativePath()
      },
      immediate: true
    },
    view: {
      handler(view) {
        window.localStorage.setItem('asset-gallery-view', view || 'list')
      },
      immediate: true
    },
    uploadIndex() {
      this.syncAssets()
    },
    'organization.storageDirectories'() {
      this.initRelativePath()
    }
  },
  beforeMount() {
    extend('folder_unique', this.folderUnique)
  },
  methods: {
    folderUnique(value) {
      return this.subdirectories.every(x => x.meta.name !== value) || 'A folder with this name already exists'
    },
    initRelativePath() {
      if (!this.storageRef) {
        return
      }
      // clear assets
      this.assets = []
      // get the storage folders saved in database
      if (!this.organization) {
        return
      }
      this.subdirectories = (this.organization.storageDirectories || [])
        .filter((x) => {
          if (this.relativePath) {
            return x.startsWith(this.relativePath) && x !== this.relativePath
          }
          x = x.startsWith('/') ? x.substring(1) : x
          return x.split('/').length === 1
        })
        .map(x => ({
          url: x,
          meta: {
            type: 'folder',
            name: x.split('/').reverse()[0]
          }
        }))
      this.syncAssets()
    },
    async syncAssets() {
      if (!this.homeDirectory) {
        return
      }
      this.loading = true
      // get the path contents from firebase storage
      // https://firebase.google.com/docs/storage/web/list-files
      const { prefixes, items, nextPageToken } = await this.storageRef.list({ maxResults: this.itemsPerPage })
      const storagePrefixes = prefixes.map(x => ({
        url: x.location.path_.substring(this.homeDirectory.length),
        meta: {
          type: 'folder',
          name: x.name
        }
      }))
      // add folders that were not saved in database but exist in firebase storage
      const prefixPaths = this.subdirectories.map(x => x.url)
      this.subdirectories = this.subdirectories.concat(storagePrefixes.filter(x => !prefixPaths.includes(x.url)))

      // load the assets
      let assets = await Promise.all(items.map(async(item) => {
        item.url = await item.getDownloadURL()
        item.meta = await item.getMetadata()
        return item
      }))
      if (this.assetType) {
        assets = assets.filter(this.typeMatch)
      }
      this.assets = assets
      // save next page token
      this.nextPageToken = nextPageToken
      this.loading = false
    },
    async loadNextPage() {
      const pageToken = this.nextPageToken
      this.nextPageToken = null
      this.loading = true
      const { prefixes, items, nextPageToken } = await this.storageRef.list({ maxResults: this.itemsPerPage, pageToken })
      // console.log({ prefixes, items, nextPageToken })

      // add folders
      if (prefixes.length) {
        const storagePrefixes = prefixes.map(x => ({
          url: x.location.path_.substring(this.homeDirectory.length),
          meta: {
            type: 'folder',
            name: x.name
          }
        }))
        const prefixPaths = this.subdirectories.map(x => x.url)
        this.subdirectories = this.subdirectories.concat(storagePrefixes.filter(x => !prefixPaths.includes(x.url)))
      }

      let pageAssets = await Promise.all(items.map(async(item) => {
        item.url = await item.getDownloadURL()
        item.meta = await item.getMetadata()
        return item
      }))
      if (this.assetType) {
        pageAssets = pageAssets.filter(this.typeMatch)
      }
      this.assets = this.assets.concat(pageAssets)
      this.nextPageToken = nextPageToken
      this.loading = false
    },
    typeMatch(item) {
      let contentType = this.assetType
      if (contentType === 'document') {
        contentType = 'application'
      }
      return item.meta.contentType.includes(contentType)
    },
    async createFolder() {
      this.creatingFolder = true
      // create folder path that always starts with /
      const newFolderPath = `${this.relativePath}/${this.newFolderName}`
      // temporary unique validation
      const storageDirectories = [...new Set((this.organization.storageDirectories || []).concat(newFolderPath))]
      await this.$db.collection('organizations').doc(this.organization.id).update({ storageDirectories })
      this.newFolderName = ''
      this.newFolderDialog = false
      await this.syncAssets()
      this.creatingFolder = false
    },
    clickItem(item) {
      if (item.meta.type === 'folder') {
        if (this.router) {
          // if path control is via router, leave handling to parent page component
          return this.$emit('path-change', item.url)
        }
        return this.goToPath(item.url)
      }
      this.$emit('select', item.url)
    },
    goToPath(path) {
      // go to the relative directory under homeDirectory
      this.relativePath = path
    },
    goUpOneDirectory() {
      const pathArr = this.relativePath.split('/')
      pathArr.pop()
      const newPath = pathArr.join('/')
      if (this.router) {
        // if path control is via router, leave handling to parent page component
        return this.$emit('path-change', newPath)
      }
      return this.goToPath(newPath)
    },
    async deleteItem(item) {
      if (item.meta.type === 'folder') {
        // check if folder contents have any assets
        const { prefixes, items } = await this.storageRef.child(item.meta.name).list({ maxResults: 1 })
        if (prefixes.length || items.length) {
          // console.log(prefixes, items)
          return alert('Non empty folders cannot be deleted at this time. Please first delete the items inside the folder.')
        }
        // remove folder from storageDirectories
        const storageDirectories = [...new Set((this.organization.storageDirectories || []).filter(x => x !== item.url))]
        await this.$db.collection('organizations').doc(this.organization.id).update({ storageDirectories })
        await this.syncAssets()
        return this.$store.dispatch('newSnackbar', { type: 'success', message: 'Folder deleted.' })
      }
      const { url } = item
      this.selectedAssetUrl = url
      if (this.references.length) {
        return alert('You can\'t delete this file as there are documents referencing it')
      }
      this.deleting = true
      this.selectedAssetRef.delete()
        .then(() => {
          this.deleting = false
          const item = this.assets.find(x => x.url === url)
          const index = this.assets.indexOf(item)
          this.assets.splice(index, 1)
          this.$store.dispatch('newSnackbar', { type: 'success', message: 'Asset deleted.' })
        })
        .catch((error) => {
          this.$store.dispatch('newSnackbar', { type: 'error', message: error.message })
          alert(error)
        })
        .finally(() => {
          this.selectedAssetUrl = null
          this.deleting = false
        })
    },
    showReferences(url) {
      this.selectedAssetUrl = url
      this.referencesDialog = true
    },
    getMComponent(layoutComponent) {
      if (layoutComponent.widgetId) {
        return this.$store.getters.MWidgetById(layoutComponent.widgetId)
      }
      if (layoutComponent.pageId) {
        return this.$store.getters.MPageById(layoutComponent.pageId)
      }
      if (layoutComponent.pluginId) {
        return this.$store.getters.MPluginById(layoutComponent.pluginId)
      }
    },
    startUpload(event) {
      this.$store.dispatch('uploader/selectFiles', {
        files: event.target.files,
        path: this.fullPath
      })
    }
  }
}
</script>

<style scoped lang="sass">
  input[type=file].upload-input
    position: fixed
    top: -99999
    right: -99999
    width: 1px
    height: 1px
    text-align: right
    filter: alpha(opacity=0)
    opacity: 0
    outline: none
    cursor: inherit
    display: block
</style>
