<template>
  <v-card outlined>
    <v-card-title>
      {{ title }}
      <v-spacer />
      <v-btn
        icon
        @click="expanded = !expanded"
      >
        <v-icon>
          mdi-chevron-{{ expanded ? 'up' : 'down' }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-expand-transition>
      <v-card v-if="expanded" flat>
        <slot />
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: 'ExpandCard',
  props: {
    title: {
      type: String,
      default: () => ''
    },
    value: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      expanded: this.value
    }
  },
  watch: {
    value(val) {
      this.expanded = val
    }
  }
}
</script>
