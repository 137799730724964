const faker = require('faker/locale/de')

const generateNewsItem = () => {
  return {
    title: faker.lorem.words(3).toUpperCase(),
    date: faker.date.recent(),
    content: faker.lorem.paragraphs()
  }
}
export default {
  title: 'Example Title',
  items: [...Array(3)].map(() => generateNewsItem())
}
