export const itemOptions = {
  title: '',
  description: '',
  icon: '',
  pageId: ''
}

export default {
  items: [
    {
      title: 'Partners',
      description: 'See our partner details',
      icon: 'account-multiple',
      pageId: null
    },
    {
      title: 'Locations',
      description: 'Full list of our locations',
      icon: 'map-marker',
      pageId: null
    },
    {
      title: 'Iframe',
      description: 'Whatever this iframe is',
      icon: 'steering',
      pageId: null
    },
    {
      title: 'Configurator',
      description: 'Configurator description',
      icon: 'car',
      pageId: null
    }
  ]
}
