<template>
  <v-card flat>
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <m-widget-container-form v-model="value" />
      <field-card label="Design & Display">
        <m-color-input
          v-model="value.textColor"
          label="Text Color"
        />
        <v-row>
          <v-col class="grow">
            <v-text-field
              v-model="value.timeFormat"
              label="Time Format"
              type="text"
              outlined
              required
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-if="value.showDate"
              v-model="value.dateFormat"
              label="Date Format"
              type="text"
              outlined
              required
            />
          </v-col>
        </v-row>
        <v-row class="wrap">
          <v-col
            v-for="option in clockOptions"
            :key="option"
            style="min-width: 300px;"
            class="grow"
          >
            <v-switch
              v-model="value[option]"
              inset
              class="ma-2 mr-4"
              :label="option | capitalizeFirstLetter"
              required
            />
          </v-col>
        </v-row>
      </field-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MClockWidgetForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    error: null,
    clockOptions: [
      'showDate'
    ]
  })
}
</script>
