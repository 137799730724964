import { db, Timestamp } from '@/plugins/firebase'

export const generateRandomPairingNumber = async(machineId) => {
  const pairingNumber = Math.random().toString().substring(2, 6)
  const doc = await db.collection('pairing-numbers').doc(pairingNumber).get()
  if (doc.exists) {
    // eslint-disable-next-line
    console.warn('exists', pairingNumber)
    return generateRandomPairingNumber(machineId)
  }
  await db.collection('pairing-numbers').doc(pairingNumber).set({
    createdAt: Timestamp.fromDate(new Date()),
    pairingNumber,
    machineId,
    resolution: {
      width: window.screen.width,
      height: window.screen.height
    }
  })
  return pairingNumber
}

export const getPairingNumber = async(machineId) => {
  const { docs } = await db.collection('pairing-numbers').where('machineId', '==', machineId).get()
  if (docs.length) {
    return docs[0].data().pairingNumber
  }
  return generateRandomPairingNumber(machineId)
}
