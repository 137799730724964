<template>
  <v-text-field
    v-model="color"
    :label="label"
    hide-details
    dense
    outlined
    class="ma-0 pa-0"
    height="55"
  >
    <template #append>
      <v-menu
        v-model="menu"
        top
        nudge-bottom="60"
        nudge-left="16"
        :close-on-content-click="false"
      >
        <template #activator="{ on }">
          <v-avatar
            :style="swatchStyle"
            size="30"
            v-on="on"
          >
            <v-icon
              v-if="!color"
              v-text="'mdi-palette'"
            />
          </v-avatar>
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker
              v-model="color"
              flat
              hide-mode-switch
              mode="hexa"
              hide-inputs
              :show-swatches="false"
              dot-size="20"
            />
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              v-if="defaultColor && color !== defaultColor"
              small
              outlined
              @click="color = defaultColor"
            >
              <v-icon
                left
                :color="defaultColor"
              >
                mdi-circle
              </v-icon>
              Reset Default
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: () => ''
    },
    label: {
      type: String,
      default: () => ''
    },
    defaultColor: {
      type: String,
      default: () => null
    }
  },
  data: () => ({
    menu: false,
    color: null
  }),
  computed: {
    swatchStyle() {
      const { color, menu } = this
      return {
        backgroundColor: color,
        borderRadius: menu ? '50%' : '4px',
        border: '1px solid grey',
        cursor: 'pointer',
        transition: 'border-radius 200ms ease-in-out',
        marginTop: '5px'
      }
    }
  },
  watch: {
    value: {
      handler(val) {
        if (this.color !== val) {
          this.color = val
        }
      },
      immediate: true
    },
    color: {
      handler(val) {
        if (val.length > 7) {
          val = val.substring(0, 7)
        }
        this.$emit('input', val)
      },
      immediate: true
    }
  },
  mounted() {
    this.$emit('input', this.value)
  }
}
</script>
