import Vue from 'vue'
import axios from 'axios'
import store from '@backend/store'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.validateStatus = (status) => {
  return status < 500 // Reject only if the status code is greater than or equal to 500
}
axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code >= 500
      store.dispatch('newSnackbar', { type: 'error', message: 'Bad server response.', error })
    } else if (error.request) {
      // The request was made but no response was received
      store.dispatch('newSnackbar', { type: 'error', message: 'Could not reach the server.', error })
    } else {
      // Something happened in setting up the request that triggered an Error
      store.dispatch('newSnackbar', { type: 'error', message: 'Bad request error.', error })
    }
    return Promise.reject(error)
  }
)

Vue.prototype.$axios = axios
Vue.prototype.$apiUrl = process.env.NODE_ENV === 'development'
  ? 'http://localhost:5001/mammutos-7ad07/us-central1/api'
  : 'https://us-central1-mammutos-7ad07.cloudfunctions.net/api'
