import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://0fd4e51ba46d426da480931963188092@o190932.ingest.sentry.io/5629040',
    integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })]
  })
}
