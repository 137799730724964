<template>
  <v-container
    class="d-flex flex-column justify-center align-center"
    style="min-height: 300px;"
  >
    <v-progress-circular
      class="mb-3"
      color="white"
      indeterminate
    />
    {{ message }}
  </v-container>
</template>

<script>
export default {
  name: 'CardLoader',
  props: {
    message: {
      type: String,
      default: () => ''
    }
  }
}
</script>
