import Vue from 'vue'

Vue.mixin({
  methods: {
    previewComponent(componentType, layoutItemId) {
      window.open(
        `/preview/${componentType}/${layoutItemId}`,
        'preview',
        `
          scrollbars=no,
          resizable=no,
          status=no,
          location=no,
          toolbar=no,
          menubar=no,
          titlebar=no,
          width=0,
          height=0,
          left=-1000,
          top=-1000
        `
      )
    }
  }
})
