import store from '@backend/store'

export default async(to, from, next) => {
  if (!store.state.user && to.name !== 'login') {
    const toRoute = { name: 'login' }
    if (from && from.fullPath && from.fullPath !== '/') {
      toRoute.query = { redirect: from.fullPath }
    }
    return next(toRoute)
  }
  if (store.state.user && !store.state.organizations.length) {
    await store.dispatch('bindUserDocuments')
  }
  if (store.state.user && !store.state.organizationId && !['organizations', 'organizations-new'].includes(to.name)) {
    if (store.state.organizations.length) {
      const organizationId = window.localStorage.organizationId || (store.state.organizations[0] || {}).id
      await store.dispatch('setOrganizationId', organizationId)
    }
    if (!store.state.organizationId && to.name !== 'get-started') {
      const toRoute = { name: 'get-started' }
      if (from && from.fullPath && !['/', '/login', '/register'].includes(from.fullPath)) {
        toRoute.query = { redirect: from.fullPath }
      }
      return next(toRoute)
    }
  }
  if (store.getters.organization && !store.getters.organization.freeTrialStartAt && !['organizations', 'organizations-new', 'get-started'].includes(to.name)) {
    return next({ name: 'get-started' })
  }
  if (store.state.user && to.name === 'login') {
    return next({ name: 'index' })
  }
  return next()
}
