<template>
  <div class="outer-container">
    <resize-observer @notify="handleResize" />
    <div ref="innerContainer" class="inner-container">
      <grid-layout
        v-if="containerWidth && containerHeight"
        ref="gridLayout"
        :prevent-collision="false"
        :layout.sync="gridLayout"
        :col-num="colNum"
        :max-rows="maxRows"
        :row-height="rowHeight"
        :vertical-compact="false"
        :margin="[0, 0]"
        :style="{ width: `${containerWidth}px`, height: `${containerHeight}px`, background: editor ? '#0000001f' : null }"
        :is-resizable="editor"
        :is-draggable="editor"
        @layout-updated="editor && layoutUpdated($event)"
      >
        <grid-item
          v-for="gItem in gridLayout"
          :key="gItem.i"
          :i="gItem.i"
          :x="gItem.x"
          :y="gItem.y"
          :w="gItem.w"
          :h="gItem.h"
          :min-h="gItem.minH"
          :min-w="gItem.minW"
          :max-h="gItem.maxH"
          :max-w="gItem.maxW"
          :is-resizable="editor && !gItem.unResizable"
        >
          <template v-if="editor">
            <v-card
              class="layout-item"
              flat
              outlined
              dark
            >
              <v-menu rigth>
                <template #activator="{ on: menu }">
                  <v-tooltip bottom>
                    <template #activator="{ on: tooltip }">
                      <v-btn
                        class="layout-item-options"
                        x-small
                        icon
                        color="white"
                        v-on="{ ...tooltip, ...menu }"
                      >
                        <v-icon small>
                          mdi-cog
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Widget Options</span>
                  </v-tooltip>
                </template>
                <v-list dense style="min-width: 150px; max-width: 600px">
                  <v-subheader class="d-flex caption ml-2">
                    {{ gItem.layoutWidget.name }}
                  </v-subheader>
                  <v-divider />
                  <v-list-item @click="$emit('item-edit', gItem.layoutWidgetId)">
                    <v-list-item-title>Edit Widget Options</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$emit('item-remove', gItem.layoutWidgetId)">
                    <v-list-item-title>Remove Widget</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <div class="layout-item-title caption text-truncate">
                {{ gItem.layoutWidget.name }}
              </div>
            </v-card>
          </template>
          <component
            :is="gItem.layoutWidget.widget.component"
            v-else-if="gItem.layoutWidget.options"
            :key="JSON.stringify(gItem.layoutWidget)"
            v-bind="gItem.layoutWidget.options"
            @open-page="$emit('open-page', $event)"
          />
        </grid-item>
      </grid-layout>
    </div>
  </div>
</template>

<script>
import { GridLayout, GridItem } from 'vue-grid-layout'
import { v1 as uuidv1 } from 'uuid'
export default {
  components: {
    GridLayout,
    GridItem
  },
  props: {
    layoutWidgets: {
      type: Array,
      default: () => []
    },
    aspectRatio: {
      type: Number,
      default: () => 1
    },
    editor: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      colNum: 20,
      maxRows: 20,
      gridLayout: [],
      containerWidth: undefined,
      containerHeight: undefined,
      showMenu: false,
      x: 0,
      y: 0
    }
  },
  computed: {
    rowHeight() {
      return this.containerHeight / this.maxRows
    }
  },
  watch: {
    layoutWidgets: {
      handler(layoutWidgets, oldLayoutWidgets) {
        this.initializeGrid()
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.handleResize()
  },
  methods: {
    initializeGrid() {
      const newLayout = []
      this.layoutWidgets.forEach((layoutWidget) => {
        if (layoutWidget.widget) {
          const { i, y, x, w, h } = layoutWidget.grid || {}
          const { unResizable, minH, maxH, minW, maxW } = layoutWidget.widget.gridConstraints || {}
          newLayout.push({
            layoutWidgetId: layoutWidget.id,
            layoutWidget,
            i: i || uuidv1(),
            y: y >= 0 ? y : newLayout.length ? 16 : 0,
            x: x >= 0 ? x : 5,
            w: w || 10,
            h: h || 1,
            unResizable,
            minH,
            maxH,
            minW,
            maxW
          })
        }
      })
      this.gridLayout = newLayout
      this.$emit('new-grid', null)
    },
    layoutUpdated(newLayout) {
      let fixed = false
      newLayout = newLayout.map((item) => {
        const { y, h } = item
        if (y + h > 20) {
          const remainingHeight = 20 - y
          if (remainingHeight > 0) {
            item.h = remainingHeight
          } else {
            item.h = 1
            item.y = 19
          }
          fixed = true
        }
        return item
      })
      if (fixed) {
        this.gridLayout = newLayout
      }
      this.$emit('new-grid', newLayout.map(({ layoutWidgetId, i, y, x, w, h }) => ({ layoutWidgetId, grid: { i, y, x, w, h } })))
    },
    handleResize() {
      if (!this.$refs.innerContainer) {
        return
      }
      const { offsetWidth, offsetHeight } = this.$refs.innerContainer
      const containerAspectRatio = offsetWidth / offsetHeight
      if (containerAspectRatio > this.aspectRatio) {
        this.containerWidth = offsetHeight * this.aspectRatio
        this.containerHeight = offsetHeight
      } else {
        this.containerWidth = offsetWidth
        this.containerHeight = offsetWidth / this.aspectRatio
      }
    },
    distributeVertically() {
      const newLayout = []
      const count = this.layoutWidgets.length
      const size = 20 / count
      let yPos = 0
      this.layoutWidgets.forEach((layoutWidget) => {
        const { i } = layoutWidget.grid || {}
        newLayout.push({
          layoutWidgetId: layoutWidget.id,
          layoutWidget,
          i: i || uuidv1(),
          y: yPos,
          x: 0,
          w: 20,
          h: size
        })
        yPos += size
      })
      this.gridLayout = newLayout
      this.layoutUpdated(newLayout)
    },
    distributeHorizontally() {
      const newLayout = []
      const count = this.layoutWidgets.length
      const size = 20 / count
      let xPos = 0
      this.layoutWidgets.forEach((layoutWidget) => {
        const { i } = layoutWidget.grid || {}
        newLayout.push({
          layoutWidgetId: layoutWidget.id,
          layoutWidget,
          i: i || uuidv1(),
          y: 0,
          x: xPos,
          w: size,
          h: 20
        })
        xPos += size
      })
      this.gridLayout = newLayout
      this.layoutUpdated(newLayout)
    }
  }
}
</script>

<style lang="sass" scoped>
  .outer-container
    width: 100%
    height: 100%
    overflow: hidden
    margin: 0
    padding: 0
    position: relative
    .inner-container
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      display: flex
      flex-flow: column nowrap
      justify-content: flex-start
      align-items: center
      .vue-grid-layout .layout-item
        width: 100%
        height: 100%
        display: flex
        justify-content: center
        align-items: center
        text-align: center
        flex-wrap: wrap
        position: relative
        .layout-item-title
          padding: 0 24px
        .layout-item-options
          position: absolute
          left: 0
          top: 0
          margin: 2px
</style>

<style lang="sass">
  .vue-grid-item > .vue-resizable-handle
    background: url("data:image/svg+xml;base64,PHN2ZyBzdHlsZT0iZmlsbDp3aGl0ZTsiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjYiIGhlaWdodD0iNiI+PHBhdGggZD0iTTYgNkgwVjQuMmg0LjJWMEg2djZ6IiBvcGFjaXR5PSIxIi8+PC9zdmc+")
    background-position: 100% 100%
    padding: 0 3px 3px 0
    background-repeat: no-repeat
    background-origin: content-box
    -webkit-box-sizing: border-box
</style>
