import Vue from 'vue'
import App from '@frame/App.vue'
import router from '@frame/router'
import store from '@frame/store'

import vuetify from '@/plugins/vuetify'
import '@/plugins'

import MLayoutGrid from '@/components/core/MLayoutGrid'
import MWidgetContainer from '@/components/core/MWidgetContainer'

import '@frame/registerServiceWorker'

Vue.component('MLayoutGrid', MLayoutGrid)
Vue.component('MWidgetContainer', MWidgetContainer)

Vue.config.productionTip = false

const initApp = async() => {
  await store.dispatch('enableFirestorePersistence')
  await store.dispatch('setAnonymousUser')
  await store.dispatch('bindMComponents')
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}
initApp()
