<template>
  <v-card flat>
    <v-card-text class="pa-0">
      <v-row align="center">
        <v-col class="grow">
          <v-select
            v-model="color"
            outlined
            :label="`Select ${label}`"
            hide-details
            :items="options"
            :clearable="!required"
          >
            <template #selection="{ item }">
              <v-avatar
                size="32"
                :color="item.value || value"
                class="mr-3"
              >
                <v-icon v-if="!item.value && !custom">
                  mdi-palette
                </v-icon>
              </v-avatar>
              {{ item.label }}
            </template>
            <template #item="{ item }">
              <v-avatar
                size="32"
                :color="item.value"
                class="mr-3"
              >
                <v-icon v-if="!item.value">
                  mdi-palette
                </v-icon>
              </v-avatar>
              {{ item.label }}
            </template>
          </v-select>
        </v-col>
        <v-col
          v-if="custom"
          cols="6"
        >
          <m-color-picker
            v-model="customColor"
            label="Pick Custom Color"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MColorInput',
  props: {
    value: {
      type: String,
      default: () => ''
    },
    label: {
      type: String,
      default: () => 'Color'
    },
    required: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      color: this.value,
      custom: false,
      customOption: {
        label: 'Custom',
        isCustom: true
      },
      customColor: ''
    }
  },
  computed: {
    layoutTheme() {
      const layoutTheme = this.$store.getters.layout ? this.$store.getters.layout.theme : null
      return layoutTheme || this.$vuetify.theme.themes.light
    },
    options() {
      const options = Object.keys(this.layoutTheme).map((x) => {
        return {
          label: this.capitalizeFirstLetter(x),
          value: this.layoutTheme[x]
        }
      })
      return [...options, this.customOption]
    }
  },
  watch: {
    color: {
      handler(newColor, oldColor) {
        if (!newColor) {
          this.custom = false
          this.$emit('input', '')
          return
        }
        const layoutColor = Object.keys(this.layoutTheme).find(key => this.layoutTheme[key] === newColor)
        let color = layoutColor || newColor
        if (color.isCustom) {
          this.custom = true
          color = this.customColor
        } else {
          this.custom = false
        }
        if (color !== oldColor) {
          this.$emit('input', color)
        }
      },
      immediate: true
    },
    customColor(customColor) {
      if (customColor) {
        this.$emit('input', customColor)
      }
    }
  },
  mounted() {
    const layoutColor = this.layoutTheme[this.value]
    if (layoutColor) {
      this.color = layoutColor
    } else if (this.color && this.color.startsWith('#')) {
      this.customColor = this.color
      this.color = this.customOption
    }
  }
}
</script>
