export const getPlanStatus = ({ minPlan, plan }) => {
  // plan will be one from trial, advertising, view, touch or null (free)
  if (minPlan === 'touch') {
    if (!['touch', 'trial'].includes(plan)) {
      return false
    }
  }
  if (minPlan === 'view') {
    if (!['touch', 'trial', 'view'].includes(plan)) {
      return false
    }
  }
  if (minPlan === 'advertising') {
    if (!plan || plan === 'free') {
      return 'free'
    }
  }
  return true
}
