<template>
  <v-hover
    v-slot="{ hover }"
    class="field-card mb-8"
    :class="{ disabled }"
  >
    <v-card
      outlined
      :style="{ borderColor: hover && !disabled ? 'black' : 'rgba(0, 0, 0, 0.22)' }"
    >
      <v-card-title v-if="title">
        {{ title }}
      </v-card-title>
      <v-card-subtitle v-if="cardSubtitle">
        {{ cardSubtitle }}
      </v-card-subtitle>
      <v-card-text class="pt-0">
        <slot />
      </v-card-text>
      <v-card-actions v-if="$slots.actions" class="flex-wrap justify-end field-card--actions">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'FieldCard',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    subtitle: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    cardSubtitle() {
      return this.subtitle || this.label
    }
  }
}
</script>

<style lang="sass">
  @import 'vuetify/src/styles/settings/_variables.scss'
  .field-card.disabled
    opacity: 0.75
    position: relative
    &:after
      content: ""
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      z-index: 2
  .field-card--actions
    .v-btn
      margin-bottom: 1em
  @media #{map-get($display-breakpoints, 'xs-only')}
    .field-card .v-card__title
      font-size: 1.1rem
</style>
