<template>
  <div>
    <input type="text" placeholder="Text input" data-layout="normal" @focus="show">
    <vue-touch-keyboard
      v-if="visible"
      :options="options"
      :layout="layout"
      :cancel="hide"
      :accept="accept"
      :input="input"
    />
  </div>
</template>

<script>
import VueTouchKeyboard from 'vue-touch-keyboard'
import 'vue-touch-keyboard/dist/vue-touch-keyboard.css' // load default style

export default {
  components: {
    'vue-touch-keyboard': VueTouchKeyboard.component
  },
  props: {
    value: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    visible: false,
    layout: 'normal',
    input: null,
    options: {
      useKbEvents: false,
      preventClickEvent: false
    }
  }),
  methods: {
    accept(text) {
      alert(`Input text: ${text}`)
      this.hide()
    },
    show(e) {
      this.input = e.target
      this.layout = e.target.dataset.layout
      if (!this.visible) {
        this.visible = true
      }
    },
    hide() {
      this.visible = false
    }
  }
}
</script>
