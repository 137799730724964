// default options
export default {
  intervalInSeconds: 5,
  items: [
    {
      assetType: 'video',
      src: 'https://firebasestorage.googleapis.com/v0/b/mammutos-7ad07.appspot.com/o/storybook-demo-organization%2Fvideos%2FUnderground_Countdown.mp4?alt=media&token=cfe9a77f-31cf-441c-a5e3-8e87c105226f'
    },
    {
      assetType: 'image',
      photo: {
        src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg'
      }
    },
    {
      assetType: 'video',
      src: 'https://firebasestorage.googleapis.com/v0/b/mammutos-7ad07.appspot.com/o/storybook-demo-organization%2Fvideos%2FUnderground_Countdown.mp4?alt=media&token=cfe9a77f-31cf-441c-a5e3-8e87c105226f'
    },
    {
      assetType: 'image',
      photo: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'
    }
  ]
}
