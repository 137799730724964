<template>
  <v-hover
    v-slot="{ hover }"
    class="mb-8"
  >
    <v-card
      outlined
      :style="{ borderColor: hover ? 'black' : null }"
    >
      <v-subheader>
        {{ label }}
      </v-subheader>
      <v-card-text class="pt-0">
        <v-text-field
          ref="searchInput"
          v-model="searchQuery"
          label="Enter a location"
          placeholder=""
          outlined
        />
        <div id="m-coord-picker" ref="map" class="m-coord-picker" />
      </v-card-text>
      <v-card-text class="pt-0">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          name="Longitude"
        >
          <v-text-field
            v-model="longitude"
            outlined
            label="Longitude"
            required
            :error-messages="errors"
          />
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          name="Latitude"
        >
          <v-text-field
            v-model="latitude"
            outlined
            label="Latitude"
            required
            :error-messages="errors"
          />
        </validation-provider>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { firebaseConfig } from '../../plugins/firebase'
export default {
  name: 'MCoordPicker',
  props: {
    value: {
      type: Array,
      default: () => [7.0747832, 51.1627533]
    },
    label: {
      type: String,
      default: () => 'Coordinates'
    }
  },
  data() {
    return {
      searchQuery: '',
      items: [],
      center: this.value,
      longitude: undefined,
      latitude: undefined,
      map: undefined,
      uuid: uuidv4(),
      autocomplete: undefined,
      markerIcon: undefined,
      marker: undefined
    }
  },
  watch: {
    value: {
      handler([longitude, latitude]) {
        this.longitude = longitude
        this.latitude = latitude
        this.setMarker({ latitude, longitude })
      },
      deep: true,
      immediate: true
    }
  },
  beforeMount() {
    const fjs = document.getElementsByTagName('script')[0]
    if (!document.getElementById('google-maps-script')) {
      const js = document.createElement('script')
      js.id = 'google-maps-script'
      js.src = `https://maps.googleapis.com/maps/api/js?key=${firebaseConfig.apiKey}&libraries=places`
      fjs.parentNode.insertBefore(js, fjs)
    }
  },
  mounted() {
    this.initMap()
  },
  methods: {
    initMap() {
      const { google } = window
      if (!google) {
        setTimeout(this.initMap, 500)
        return
      }
      const { latitude, longitude } = this
      this.map = new google.maps.Map(this.$refs.map, {
        center: { lat: latitude, lng: longitude },
        zoom: 14
      })
      google.maps.event.addListener(this.map, 'click', this.mapOnClick)
      const input = this.$refs.searchInput.$el.getElementsByTagName('input')[0]
      this.autocomplete = new google.maps.places.Autocomplete(input)
      this.autocomplete.setFields(['name', 'geometry'])
      google.maps.event.addListener(this.autocomplete, 'place_changed', this.change)
      this.markerIcon = {
        url: require('./assets/map-marker.svg'),
        size: new google.maps.Size(71, 71),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 34),
        scaledSize: new google.maps.Size(25, 25)
      }
      this.setMarker({ latitude, longitude })
    },
    setMarker({ latitude, longitude }) {
      if (this.map) {
        const location = { lat: latitude, lng: longitude }
        this.map.panTo(location)
        this.marker && this.marker.setMap(null)
        this.marker = new window.google.maps.Marker({
          map: this.map,
          position: location,
          animation: window.google.maps.Animation.DROP,
          icon: this.markerIcon
        })
      }
    },
    change() {
      const { geometry } = this.autocomplete.getPlace()
      if (geometry && geometry.location) {
        const { lat, lng } = geometry.location
        this.$emit('input', [lng(), lat()])
      } else {
        this.searchQuery = ''
      }
    },
    mapOnClick({ latLng }) {
      const { lat, lng } = latLng
      this.$emit('input', [lng(), lat()])
    }
  }
}
</script>

<style lang="sass">
  .m-coord-picker
    width: 100%
    height: 300px
</style>
