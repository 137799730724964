import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { getPairingNumber } from './utils.js'
import { db, getAsyncCurrentUser, auth } from '@/plugins/firebase'
import { registerMComponents } from '@/utils/resolve_widgets'

Vue.use(Vuex)

export const state = () => ({
  pairingNumber: undefined,
  display: undefined,
  layout: undefined,
  layoutWidgets: [],
  layoutPages: [],
  layoutPlugins: [],
  MWidgets: [],
  MPlugins: [],
  MPages: []
})

// getters
export const getters = {
  pairingNumber: state => state.pairingNumber,
  display: state => state.display,
  layout: (state, getters) => {
    const layout = state.layout
    if (!layout) {
      return layout
    }
    layout.widgets = state.layoutWidgets.map((layoutWidget) => {
      layoutWidget.widget = getters.MWidgets.find(widget => widget.id === layoutWidget.widgetId)
      return layoutWidget
    })
    layout.plugins = state.layoutPlugins.map((layoutPlugin) => {
      layoutPlugin.plugin = getters.MPlugins.find(plugin => plugin.id === layoutPlugin.pluginId)
      return layoutPlugin
    })
    return layout
  },
  layoutById: (state, getters) => id => getters.layouts.find(layout => layout.id === id),
  layoutWidgets: state => state.layoutWidgets.map((layoutWidget) => {
    layoutWidget.widget = state.MWidgets.find(widget => widget.id === layoutWidget.widgetId)
    return layoutWidget
  }),
  layoutWidgetById: (state, getters) => id => getters.layoutWidgets.find(layoutWidget => layoutWidget.id === id),
  layoutPages: state => state.layoutPages.map((layoutPage) => {
    layoutPage.page = state.MPages.find(page => page.id === layoutPage.pageId)
    return layoutPage
  }),
  layoutPageById: (state, getters) => id => getters.layoutPages.find(layoutPage => layoutPage.id === id),
  layoutPlugins: state => state.layoutPlugins.map((layoutPlugin) => {
    layoutPlugin.plugin = state.MPlugins.find(plugin => plugin.id === layoutPlugin.pluginId)
    return layoutPlugin
  }),
  layoutPluginById: (state, getters) => id => getters.layoutPlugins.find(layoutPlugin => layoutPlugin.id === id),
  layoutTheme: state => state.layout ? state.layout.theme : null,
  MWidgets: state => state.MWidgets,
  MWidgetById: state => id => state.MWidgets.find(widget => widget.id === id),
  MPages: state => state.MPages,
  MPageById: state => id => state.MPages.find(page => page.id === id),
  MPlugins: state => state.MPlugins,
  MPluginById: state => id => state.MPlugins.find(plugin => plugin.id === id)
}

// mutations
export const mutations = {
  SET_DISPLAY(state, display) {
    state.display = display
  },
  SET_PAIRING_NUMBER(state, pairingNumber) {
    state.pairingNumber = pairingNumber
  },
  ...vuexfireMutations
}

// actions
export const actions = {
  async enableFirestorePersistence() {
    try {
      await db.enablePersistence()
    } catch (err) {
      // eslint-disable-next-line
      console.warn('enablePersistence error', err)
    }
  },
  async setAnonymousUser({ state, commit }) {
    const user = await getAsyncCurrentUser()
    if (!user) {
      await auth.signInAnonymously()
    }
  },
  bindDisplay: firestoreAction(async({ state, commit, bindFirestoreRef }, machineId) => {
    await bindFirestoreRef('display', db.collection('displays').doc(machineId))
    if (!state.display && !state.pairingNumber) {
      const pairingNumber = await getPairingNumber(machineId)
      commit('SET_PAIRING_NUMBER', pairingNumber)
    }
  }),
  bindLayout: firestoreAction(async({ state, bindFirestoreRef }, layoutId) => {
    await bindFirestoreRef('layout', db.collection('layouts').doc(layoutId))
    bindFirestoreRef('layoutWidgets', db.collection('layout-widgets').where('layoutId', '==', state.layout.id))
    bindFirestoreRef('layoutPages', db.collection('layout-pages').where('layoutId', '==', state.layout.id))
    bindFirestoreRef('layoutPlugins', db.collection('layout-plugins').where('layoutId', '==', state.layout.id))
  }),
  bindMComponents: firestoreAction(async({ state, bindFirestoreRef }) => {
    await bindFirestoreRef('MWidgets', db.collection('m-widgets'))
    await bindFirestoreRef('MPages', db.collection('m-pages'))
    await bindFirestoreRef('MPlugins', db.collection('m-plugins'))
    registerMComponents({
      widgets: [...state.MWidgets.map(x => x.component)],
      pages: [...state.MPages.map(x => x.component)],
      plugins: [...state.MPlugins.map(x => x.component)]
    })
  })
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})
