import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'
// import de from 'vee-validate/dist/locale/de.json'
import * as rules from 'vee-validate/dist/rules'

/* eslint-disable */
Object.keys(rules).forEach((key) => {
  extend(key, rules[key])
})
/* eslint-enable */

localize('en', en)

extend('url', {
  validate: value => value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g) !== null,
  message: 'The given url is not a valid link'
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
