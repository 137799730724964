const faker = require('faker/locale/de')
const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const personOptions = () => {
  return {
    name: `${faker.name.firstName()} ${faker.name.lastName()}`,
    position: faker.name.jobTitle(),
    photo: faker.image.avatar(),
    phones: [...Array(randomIntFromInterval(1, 2))].map(() => ({ phone: faker.phone.phoneNumber() })),
    fax: faker.phone.phoneNumber(),
    email: faker.internet.email()
  }
}

export default {
  items: [...Array(3)].map(() => personOptions())
}
