<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="fullscreen"
    max-width="600px"
    scrollable
  >
    <v-card
      flat
    >
      <v-card-title>
        Edit Image
        <v-spacer />
        <v-btn
          fab
          text
          class="mt-n2 mr-n2"
          @click="fullscreen = !fullscreen"
        >
          <v-icon>mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn
          fab
          text
          class="mt-n2 mr-n4"
          @click="$emit('input', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text flex-wrap>
        <v-container pa-0 fluid>
          <v-row class="flex-wrap">
            <v-col>
              <image-manipulator v-model="image" />
            </v-col>
            <v-col>
              <v-container grid-list-xl>
                <v-row class="flex-wrap">
                  <v-col cols="6">
                    <v-subheader>
                      Portrait
                    </v-subheader>
                    <v-img
                      :key="image | mImage"
                      :src="image | mImage"
                      elevation="5"
                      width="300"
                      :aspect-ratio="0.5"
                    >
                      <template #placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular indeterminate />
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                  <v-col cols="6">
                    <v-subheader>
                      Square
                    </v-subheader>
                    <v-img
                      :key="image | mImage"
                      :src="image | mImage"
                      elevation="5"
                      width="300"
                      :aspect-ratio="1"
                    >
                      <template #placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular indeterminate />
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                  <v-col cols="6">
                    <v-subheader>
                      Landscape
                    </v-subheader>
                    <v-img
                      :key="image | mImage"
                      :src="image | mImage"
                      elevation="5"
                      width="300"
                      :aspect-ratio="2"
                    >
                      <template #placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular indeterminate />
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                  <v-col cols="6">
                    <v-subheader>
                      Panorama
                    </v-subheader>
                    <v-img
                      :key="image | mImage"
                      :src="image | mImage"
                      elevation="5"
                      width="300"
                      :aspect-ratio="4"
                    >
                      <template #placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular indeterminate />
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ImageManipulator from './ImageManipulator'
export default {
  name: 'ImageEditor',
  components: {
    ImageManipulator
  },
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    edit: {
      type: [Object, String],
      default: () => {}
    }
  },
  data: () => ({
    image: null,
    fullscreen: false
  }),
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    edit: {
      handler(image) {
        if (typeof image === 'string') {
          image = { src: image }
        }
        this.image = image
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    save() {
      this.$emit('saved', this.image)
    }
  }
}
</script>
