<template>
  <div>
    <pair v-if="!display" />
    <template v-else>
      <layout v-if="display.layoutId" :layout-id="display.layoutId" :plan-status="planStatus" />
      <v-dialog
        v-else
        :value="true"
        persistent
        max-width="30rem"
        transition="dialog-transition"
      >
        <v-card>
          <template v-if="!layout">
            <v-card-title class="justify-center display-1">
              <v-icon color="success" large left>
                mdi-check-circle
              </v-icon>
              Sync complete!
            </v-card-title>
            <v-card-title class="text-underline body-2 grey--text pb-0 justify-center" style="text-decoration: underline;">
              Display Name
            </v-card-title>
            <v-card-title class="font-weight-bold justify-center text-center pt-0 pb-3" style="word-break: break-word;">
              {{ display.name }}
            </v-card-title>
            <v-card-title class="justify-center headline text-center pb-3" style="word-break: break-word;">
              Please configure a layout from the backend to start displaying content on your screen.
            </v-card-title>
          </template>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import io from 'socket.io-client'
import { mapGetters } from 'vuex'
import Pair from '@frame/components/Pair'
import Layout from '@frame/views/Layout'
import { getPlanStatus } from '@/utils/get_plan_status'
export default {
  name: 'Display',
  components: {
    Pair,
    Layout
  },
  props: {
    machineId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    socket: null,
    screenshotInterval: null,
    screenshotIntervalInMinutes: 10,
    appVersion: null,
    latitude: null,
    longitude: null
  }),
  computed: {
    ...mapGetters(['display', 'layout']),
    planStatus() {
      if (!this.layout) {
        return true
      }
      return this.getPlanStatus({ minPlan: this.layout.minPlan, plan: this.display.plan })
    }
  },
  watch: {
    // reload if display gets removed
    display(display, oldDisplay) {
      if (oldDisplay && !display) {
        window.location.reload()
      }
    },
    'display.layoutId': {
      handler(newId, oldId) {
        !!newId && !!oldId && newId !== oldId && window.location.reload()
      },
      immediate: true
    }
  },
  beforeMount() {
    window.addEventListener('online', this.onOnline)
    window.addEventListener('offline', this.onOffline)
    window.addEventListener('message', this.onMessage)
  },
  beforeDestroy() {
    // just do a force restart instead of destroy
    window.location.reload(true)
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.sendMessageToElectron({ key: 'handshake:init' })
      await this.$store.dispatch('bindDisplay', this.machineId)
      await this.getCoords()
      if (this.display && this.display.id) {
        navigator.onLine && this.start()
      } else {
        setTimeout(this.init, 5000)
      }
    },
    onOnline() {
      window.location.reload()
    },
    getPlanStatus,
    start() {
      this.connectToSocket()
      setTimeout(this.takeScreenshot, 5000)
      this.screenshotInterval = setInterval(this.takeScreenshot, this.screenshotIntervalInMinutes * 60 * 1000)
    },
    onOffline() {
      this.screenshotInterval && clearInterval(this.screenshotInterval)
    },
    connectToSocket() {
      if (this.display && this.display.id && (!this.socket || !this.socket.connected)) {
        const isDev = process.env.NODE_ENV === 'development'
        const url = isDev
          ? 'http://localhost'
          : 'https://mammut.isokosan.com'
        this.socket = io(`${url}:8443`, {
          secure: true,
          query: {
            machineId: this.display.id,
            appVersion: this.appVersion,
            frameVersion: require('@/../package.json').version,
            longitude: this.longitude,
            latitude: this.latitude,
            isDev
          }
        })
        this.socket.on('connect', () => {
          console.log('connected to socket')
        })
        this.socket.on(this.display.id, (action) => {
          switch (action) {
            case 'shutdown':
              window.parent.postMessage({ channel: 'mammutos-frame', key: 'shutdown' }, '*')
              break
            case 'restart':
              window.parent.postMessage({ channel: 'mammutos-frame', key: 'shutdown', args: '-r -f -t 0' }, '*')
              break
            case 'reload-query':
              this.reload('query')
              break
            case 'reload-frame':
              this.reload('frame')
              break
            case 'reload':
              this.reload()
              break
            case 'screenshot':
              window.parent.postMessage({ channel: 'mammutos-frame', key: 'screenshot:request' }, '*')
              break
            default:
              break
          }
        })
      }
    },
    async onMessage({ data, source }) {
      const machineId = (this.display || {}).id
      if (data.channel === 'mammutos-electron') {
        // eslint-disable-next-line
        console.log('message from electron:', data)
        switch (data.key) {
          case 'handshake:complete':
            this.appVersion = data.appVersion
            break
          case 'screenshot:response':
            if (machineId) {
              const dataURL = data.value.split(',')[1]
              if (dataURL) {
                await this.$store.dispatch('setAnonymousUser')
                this.$storageRef.child(`screenshots/${machineId}.png`).putString(dataURL, 'base64', { contentType: 'image/png' })
              }
            }
            break
          default:
            break
        }
      }
    },
    async getCoords() {
      try {
        const { coords } = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition((coords) => {
            resolve(coords)
          }, (err) => {
            reject(err)
          }, { timeout: 5000 })
        })
        const { latitude, longitude } = coords
        this.latitude = latitude
        this.longitude = longitude
      } catch {
        this.latitude = null
        this.longitude = null
      }
    },
    sendMessageToElectron(data) {
      window.parent.postMessage({ ...data, channel: 'mammutos-frame' }, '*')
    },
    takeScreenshot() {
      this.sendMessageToElectron({ key: 'screenshot:request' })
    },
    async reload(reloadType) {
      // delete all caches except videos when reloading to get the latest assets
      if (window.caches) {
        const cacheNames = await window.caches.keys()
        for (const name of cacheNames.filter(name => name !== 'videos')) {
          await caches.delete(name)
        }
      }
      // clear local storage
      window.localStorage && window.localStorage.clear()
      // reload mainWindow
      window.parent.postMessage({
        channel: 'mammutos-frame',
        key: 'reload',
        reloadType
      }, '*')
      window.location.reload(true)
    }
  }
}
</script>
