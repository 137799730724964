// Determine app to start from hostname
const getApp = function() {
  const { hostname } = window.location
  if (process.env.NODE_ENV === 'development') {
    if (hostname === 'backend.mammutos.local') {
      return 'backend'
    }
    if (hostname === 'frame.mammutos.local') {
      return 'frame'
    }
    throw new Error('Could not get app from hostname')
  }
  if (hostname === 'backend.mammutos.com') {
    return 'backend'
  }
  if (hostname === 'frame.mammutos.com') {
    return 'frame'
  }
  throw new Error('Could not get app from hostname')
}

const appName = getApp()
require(`./${appName}/main.js`)
