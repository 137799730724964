const faker = require('faker/locale/de')

const generateWarning = () => {
  return {
    icon: 'alert',
    title: faker.lorem.words(2).toUpperCase(),
    subtitle: faker.lorem.sentence()
  }
}
export default {
  intervalInSeconds: 5,
  textColor: '',
  backgroundColor: '',
  iconColor: '',
  titleColor: '',
  items: [...Array(3)].map(() => generateWarning())
}
