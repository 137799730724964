<template>
  <router-view
    @screenshot="screenshot"
    @broadcast="broadcast"
    @stop-broadcast="stopBroadcast"
    @reload="reload"
    @reload-frame="reloadFrame"
    @reload-query="reloadQuery"
    @restart="restart"
    @shutdown="shutdown"
  />
</template>

<script>
import io from 'socket.io-client'
import { mapGetters } from 'vuex'
import { firebaseConfig } from '@/plugins/firebase'
export default {
  name: 'App',
  data: () => ({
    socket: null,
    socketBaseUrl: process.env.NODE_ENV === 'development'
      ? 'http://localhost'
      : 'https://mammut.isokosan.com'
  }),
  computed: {
    ...mapGetters(['user', 'organization', 'layout'])
  },
  watch: {
    'organization.id': {
      async handler(organizationId, oldOrganizationId) {
        if (organizationId !== oldOrganizationId) {
          if (this.socket && this.socket.connected) {
            this.socket.disconnect()
          }
          if (!organizationId) {
            return
          }
          this.socket = io(`${this.socketBaseUrl}:8443`, {
            secure: true,
            query: { organizationId }
          })
          this.socket.on('connect', () => {
            // eslint-disable-next-line
            console.log('connected to socket')
          })
          this.socket.on(this.organizationId, () => {
            // eslint-disable-next-line
            console.log('event specific to organization received')
          })
          const { stripeCustomerId } = this.organization
          if (stripeCustomerId) {
            const { data } = await this.$axios.get(`${this.$apiUrl}/stripe/subscription/`, {
              params: { stripeCustomerId }
            })
            this.$store.dispatch('setSubscription', data)
          } else {
            this.$store.dispatch('setSubscription', null)
          }
        }
      },
      immediate: true
    },
    'user.uid': {
      immediate: true,
      handler(uid) {
        if (!uid) {
          return
        }
        const { email, displayName, metadata } = this.user

        // identify tidio user
        const data = {
          distinct_id: uid,
          email,
          name: displayName
        }
        if (!window.tidioChatApi) {
          document.tidioIdentify = data
        } else {
          window.tidioChatApi.setVisitorData(data)
        }
        // identify userpilot user
        window.userpilot && window.userpilot.identify(uid, {
          name: displayName,
          email,
          created_at: parseInt(metadata.a / 1000)
        })
      }
    }
  },
  mounted() {
    const html = document.getElementsByTagName('html')[0]
    html.className = html.className.replace(/\bloading\b/, '')
    const fjs = document.getElementsByTagName('script')[0]
    if (!document.getElementById('google-maps-script')) {
      const js = document.createElement('script')
      js.id = 'google-maps-script'
      js.src = `https://maps.googleapis.com/maps/api/js?key=${firebaseConfig.apiKey}&libraries=places`
      fjs.parentNode.insertBefore(js, fjs)
    }
  },
  methods: {
    screenshot(displayId) {
      if (this.socket && this.socket.connected) {
        this.socket.emit('screenshot', { displayId })
      }
    },
    broadcast(displayId) {
      if (this.socket && this.socket.connected) {
        this.socket.emit('broadcast', { displayId })
      }
    },
    stopBroadcast(displayId) {
      if (this.socket && this.socket.connected) {
        this.socket.emit('stop-broadcast', { displayId })
      }
    },
    reload(displayId) {
      if (this.socket && this.socket.connected) {
        this.socket.emit('reload', { displayId })
      }
    },
    reloadFrame(displayId) {
      if (this.socket && this.socket.connected) {
        this.socket.emit('reload-frame', { displayId })
      }
    },
    reloadQuery(displayId) {
      if (this.socket && this.socket.connected) {
        this.socket.emit('reload-query', { displayId })
      }
    },
    restart(displayId) {
      if (this.socket && this.socket.connected) {
        this.socket.emit('restart', { displayId })
      }
    },
    shutdown(displayId) {
      if (this.socket && this.socket.connected) {
        this.socket.emit('shutdown', { displayId })
      }
    }
  }
}
</script>

<style lang="sass">
  @import 'vuetify/src/styles/settings/_variables.scss'

  // hide tidio chat
  html:not(.help) #tidio-chat
    opacity: 0
    z-index: -1
    display: none

  #mammut-os
    .position-absolute
      position: absolute
    .position-relative
      position: relative
    .fill-absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
    .container
      @media #{map-get($display-breakpoints, 'xs-only')}
        padding: 0
    iframe
      border: 0
    .v-btn, .v-tab
      text-transform: capitalize
    .theme--light.v-tabs-items
      background-color: unset
    .v-input__append-inner--centered
      .v-input__append-inner
        margin-top: 0
        margin-bottom: 0
        align-self: center
    .m-data-table
      .v-data-table__mobile-row
        flex-wrap: wrap
        // justify-content: flex-end
        .v-data-table__mobile-row__header
          width: 100%
          padding: 1em 0 1em 0
        .v-data-table__mobile-row__cell
          margin-left: 0.5em
          padding-bottom: 0.5em
    .v-list-item.wrapped
      flex-wrap: wrap
      @media #{map-get($display-breakpoints, 'xs-only')}
        padding: 0
        .v-list-item__icon:first-child
          margin-left: 8px
          margin-right: 24px
        .v-list-item__content
          min-width: 150px
          padding: 0
          .v-input__control
            padding-top: 10px
        .v-list-item__action
          width: 100%
          margin-top: 0
          display: flex
          justify-content: flex-end
          flex-wrap: wrap
          padding: 8px 0
        .v-btn
          margin-top: 1em
  .v-alert__icon
    align-self: unset
</style>
