export default {
  options: {
    center: [7.077049994120423, 51.162718561623535],
    zoom: 12.3,
    minZoom: 16,
    showOrientation: true,
    // 0 => North, 90 => East, 180 => South, 270 => West
    orientationAngle: 120,
    useOfflineStorage: false,
    offlineStorageRadiusKm: 100,
    categoriesBackground: 'secondary'
  },
  categories: [
    {
      id: 'sportspot',
      title: 'Sport Spots',
      icon: 'https://image.flaticon.com/icons/svg/2242/2242132.svg'
    }
  ],
  layers: [
    {
      id: 'sportspot_sportplatz',
      title: 'Sportplatz',
      icon: 'https://geoportal.solingen.de/buergerservice1/ol3/solingen_symbols/XE_Sport_Skateranlage.png',
      category: 'sportspot'
    },
    {
      id: 'sportspot_stadion',
      title: 'Stadion',
      icon: 'https://geoportal.solingen.de/buergerservice1/ol3/solingen_symbols/XE_Baden_Freibad.png',
      category: 'sportspot'
    }
  ],
  markers: [
    {
      title: 'Sportanlage Höher Heide',
      coordinates: [7.007906811329093, 51.139082626671666],
      popupContent: '<div id=\'popupContent\'><b>Sportanlage Höher Heide</b><br><br>42699  Solingen<br>Josefstal<br><br></div>',
      layer: 'sportspot_sportplatz'
    },
    {
      title: 'Sporthalle Börkhaus-Siebels',
      coordinates: [7.014232137365879, 51.148255622081734],
      popupContent: '<div id=\'popupContent\'><b>Herbert-Schade-Sportanlage Schaberg</b><br><br>42659  Solingen<br>Krahenhöher Weg<br><br></div>',
      layer: 'sportspot_sportplatz'
    },
    {
      title: 'Sportanlage Zietenstraße',
      coordinates: [7.096030443834562, 51.166055871433855],
      popupContent: '<div id=\'popupContent\'><b>Sportanlage Zietenstraße</b><br><br>42651  Solingen<br>Zietenstraße<br><br></div>',
      layer: 'sportspot_sportplatz'
    },
    {
      title: 'Stadion Wald - Jahnkampfbahn',
      coordinates: [7.036916328886975, 51.1851514910399],
      popupContent: '<div id=\'popupContent\'><b>Stadion Wald - Jahnkampfbahn</b><br><br>42719  Solingen<br>Zwergstraße<br><br></div>',
      layer: 'sportspot_stadion'
    }
  ]
}
