// default options
export default {
  intervalInSeconds: 5,
  seconds: 300,
  screensaverActiveTime: 300,
  items: [
    {
      photo: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'
    },
    {
      photo: {
        src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg'
      }
    },
    {
      photo: {
        src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg'
      }
    },
    {
      photo: {
        src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg'
      }
    }
  ]
}
