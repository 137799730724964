export default [
  // BASE LAYOUT
  {
    path: '/',
    component: () => import('@backend/layouts/base.vue'),
    children: [
      // { path: '/', name: 'index', component: () => import('@backend/pages/index.vue') },
      { path: '/', name: 'index', redirect: '/displays' },
      { path: '/account', name: 'account', component: () => import('@backend/pages/account.vue') },
      { path: '/get-started', name: 'get-started', component: () => import('@backend/pages/get-started.vue') },
      { path: '/organizations', name: 'organizations', component: () => import('@backend/pages/organizations/index.vue') },
      { path: '/organizations/new', name: 'organizations-new', component: () => import('@backend/pages/organizations/new.vue') },
      { path: '/displays', name: 'displays', component: () => import('@backend/pages/displays/index.vue') },
      { path: '/displays/:id', name: 'display', component: () => import('@backend/pages/displays/_id.vue') },
      { path: '/pair/:pairingNumber', name: 'pair', component: () => import('@backend/pages/pair.vue') },
      { path: '/layouts', name: 'layouts', component: () => import('@backend/pages/layouts/index.vue') },
      { path: '/layouts/new', name: 'layouts-new', meta: { title: 'Create A New Layout' }, component: () => import('@backend/pages/layouts/new.vue') },
      {
        path: '/layouts/:id',
        component: () => import('@backend/pages/layouts/_id.vue'),
        children: [
          { path: '', name: 'layout', component: () => import('@backend/pages/layouts/_id/index.vue') },
          { path: 'widgets', name: 'layout-widgets', component: () => import('@backend/pages/layouts/_id/widgets.vue') },
          { path: 'pages', name: 'layout-pages', component: () => import('@backend/pages/layouts/_id/pages.vue') },
          { path: 'plugins', name: 'layout-plugins', component: () => import('@backend/pages/layouts/_id/plugins.vue') },
          { path: 'theme', name: 'layout-theme', component: () => import('@backend/pages/layouts/_id/theme.vue') }
        ]
      },
      {
        path: '/media',
        component: () => import('@backend/pages/media.vue'),
        children: [
          { path: '', name: 'media', component: () => import('@backend/pages/media.vue') },
          { path: '*', name: 'media-path', component: () => import('@backend/pages/media.vue') }
        ]
      },
      {
        path: '/settings',
        component: () => import('@backend/pages/settings.vue'),
        children: [
          { path: '', name: 'settings', component: () => import('@backend/pages/settings/index.vue') },
          { path: 'subscription', name: 'subscription', component: () => import('@backend/pages/settings/subscription.vue') },
          { path: 'billing', name: 'billing', component: () => import('@backend/pages/settings/billing.vue') },
          { path: 'team', name: 'team', component: () => import('@backend/pages/settings/team.vue') }
        ]
      },
      { path: '/downloads', name: 'downloads', meta: { title: 'Downloads' }, component: () => import('@backend/pages/downloads.vue') }
    ]
  },
  // AUTH LAYOUT
  {
    path: '/',
    component: () => import('@backend/layouts/auth.vue'),
    children: [
      // { meta: { title: 'Register' }, path: '/register', name: 'register', component: () => import('@backend/pages/auth/register.vue') },
      { path: '/login', name: 'login', component: () => import('@backend/pages/auth/login.vue') }
    ]
  },
  // NO LAYOUT
  { path: '/preview/:type/:id', name: 'preview', component: () => import('@backend/pages/preview.vue') },
  // { path: '/404', name: '404', component: () => import('@backend/pages/error/Code404.vue') },
  // { path: '/403', name: '403', component: () => import('@backend/pages/error/Code403.vue') },
  { path: '*', redirect: { name: 'displays' } }
]
