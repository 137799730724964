<template>
  <div>
    <v-hover
      v-slot="{ hover }"
      class="mb-8"
    >
      <v-card
        outlined
        :style="{ borderColor: hover ? 'black' : null }"
      >
        <v-subheader>
          {{ pluralLabel }}
        </v-subheader>
        <v-card-text class="pt-0">
          <m-grid-array
            v-model="items"
            :item-photo="itemPhoto"
            :item-icon="itemIcon"
            :item-title="itemTitle"
            :item-subtitle="itemSubtitle"
            :before-delete="beforeDelete"
            @edit-item="edit($event)"
          >
            <template #[`item.icon`]="{ item }">
              <slot name="item.icon" :item="item" />
            </template>
            <template #[`item.photo`]="{ item }">
              <slot name="item.photo" :item="item" />
            </template>
            <template #[`item.title`]="{ item }">
              <slot name="item.title" :item="item" />
            </template>
            <template #[`item.subtitle`]="{ item }">
              <slot name="item.subtitle" :item="item" />
            </template>
          </m-grid-array>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            outlined
            @click="add"
          >
            Add {{ name || 'item' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-hover>
    <v-dialog
      v-model="dialog"
      max-width="600px"
      scrollable
    >
      <validation-observer
        v-if="dialog"
        ref="itemForm"
        v-slot="{ valid }"
        slim
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer />
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pt-4">
            <slot :editItem="editItem" />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              :disabled="!valid"
              @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
import { v4 as uuidv1 } from 'uuid'
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: () => ''
    },
    pluralName: {
      type: String,
      default: () => ''
    },
    itemIcon: {
      type: String,
      default: () => ''
    },
    itemPhoto: {
      type: String,
      default: () => ''
    },
    itemTitle: {
      type: String,
      default: () => ''
    },
    itemSubtitle: {
      type: String,
      default: () => ''
    },
    beforeDelete: {
      type: Function,
      default: () => true
    },
    defaultItem: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialog: false,
      editIndex: -1,
      editItem: {},
      errors: null,
      items: []
    }
  },
  computed: {
    formTitle() {
      return `${this.editIndex === -1 ? 'New' : 'Edit'} ${this.name}`
    },
    pluralLabel() {
      if (!this.name) {
        return ''
      }
      return this.pluralName || `${this.name}s`
    }
  },
  watch: {
    value: {
      handler(value) {
        this.items = value
      },
      deep: true,
      immediate: true
    },
    items: {
      handler(value) {
        if (this.items !== this.value) {
          this.$emit('input', value)
        }
      },
      deep: true
    }
  },
  methods: {
    add() {
      this.editIndex = -1
      this.editItem = Object.assign({}, this.defaultItem)
      this.dialog = true
    },
    edit(item) {
      this.editIndex = this.items.indexOf(item)
      this.editItem = Object.assign({}, item)
      this.dialog = true
    },
    save() {
      this.dialog = false
      if (this.editIndex > -1) {
        Object.assign(this.items[this.editIndex], this.editItem)
        this.$emit('input', this.items)

        return
      }
      if (!this.editItem.id) {
        this.editItem.id = uuidv1()
      }
      this.$emit('input', [...this.items, this.editItem])
    }
  }
}
</script>
