<template>
  <div
    ref="container"
    class="manipulator--container"
  >
    <v-overlay v-if="!loaded" absolute>
      <v-row
        class="fill-height ma-0"
        align="center"
        justify="center"
      >
        <v-progress-circular indeterminate />
      </v-row>
    </v-overlay>
    <vue-cropper
      ref="cropper"
      :src="src"
      :zoomable="false"
      :cropend="cropEnd"
      :ready="ready"
      :view-mode="3"
      :min-canvas-width="300"
    />
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
export default {
  name: 'ImageManipulator',
  components: { VueCropper },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    src: '',
    loaded: false
  }),
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.src = value.src || value
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ready() {
      this.$nextTick(() => {
        this.loaded = true
        const { extract } = this.value
        const { cropper } = this.$refs
        if (!extract || !cropper) {
          return
        }
        const imageData = cropper.getImageData()
        const [left, top, width, height] = extract.split('-')
        const crop = {
          left: left * imageData.width / imageData.naturalWidth || 0,
          top: top * imageData.height / imageData.naturalHeight || 0,
          width: width * imageData.width / imageData.naturalWidth || imageData.width,
          height: height * imageData.height / imageData.naturalHeight || imageData.height
        }
        this.$refs.cropper.setCropBoxData(crop)
      })
    },
    cropEnd() {
      const { cropper } = this.$refs
      const imageData = cropper.getImageData()
      const { left, top, width, height } = cropper.getCropBoxData()
      const extract = [
        parseInt(imageData.naturalWidth * left / imageData.width) || 0,
        parseInt(imageData.naturalHeight * top / imageData.height) || 0,
        parseInt(imageData.naturalWidth * width / imageData.width) || 0,
        parseInt(imageData.naturalHeight * height / imageData.height) || 0
      ].join('-')
      this.$emit('input', { src: this.src, extract })
    }
  }
}
</script>

<style lang="sass">
  .manipulator--container
    width: 100%
    min-width: 300px
    background: #FFFFFF4d
    position: relative
</style>
