<template>
  <v-container pa-0>
    <v-alert
      v-if="error"
      dense
      outlined
      type="error"
    >
      {{ error }}
    </v-alert>
    <v-row>
      <v-col class="flex-grow-0">
        <v-card
          v-if="assetType === 'video'"
          width="200"
          height="200"
        >
          <v-overlay
            v-if="uploadProgress"
            absolute
            class="fill-height d-flex justify-center align-center"
          >
            <v-progress-circular :value="uploadProgress" />
          </v-overlay>
          <video
            v-else
            :src="value"
            controls
            preload="metadata"
            width="200"
            height="200"
          />
        </v-card>
        <v-avatar
          v-else
          color="grey"
          tile
          size="200"
        >
          <v-img
            v-if="assetType === 'image'"
            :src="value | mImage({ w: 200, h: 200, fit: 'contain' })"
            max-width="200"
            max-height="200"
            contain
          >
            <template #loading>
              <v-container class="fill-height d-flex justify-center align-center">
                <v-progress-circular indeterminate />
              </v-container>
            </template>
            <template v-if="uploadProgress">
              <v-container class="fill-height d-flex justify-center align-center">
                <v-progress-circular :value="uploadProgress" />
              </v-container>
            </template>
          </v-img>
          <v-img
            v-else
            max-width="200"
            max-height="200"
            contain
          >
            <div class="asset-preview--document">
              <div class="d-flex flex-column align-center justify-center">
                <v-icon dark>
                  mdi-file-outline
                </v-icon>
                <div class="caption white--text text-truncate pa-2">
                  {{ filename }}
                </div>
              </div>
            </div>
            <template #loading>
              <v-container class="fill-height d-flex justify-center align-center">
                <v-progress-circular indeterminate />
              </v-container>
            </template>
            <template v-if="uploadProgress">
              <v-container class="fill-height d-flex justify-center align-center">
                <v-progress-circular :value="uploadProgress" />
              </v-container>
            </template>
          </v-img>
        </v-avatar>
      </v-col>
      <v-col class="d-flex flex-column justify-center">
        <v-btn
          class="ma-2 upload-button"
          outlined
          @click.stop="$refs.uploadButton.click()"
        >
          <v-icon left v-text="'mdi-cloud-upload-outline'" />
          Upload
          <input ref="uploadButton" type="file" @change="fileSelected">
        </v-btn>
        <v-btn
          class="ma-2"
          outlined
          @click="browse = true"
        >
          <v-icon left v-text="'mdi-image-search-outline'" />
          Browse
        </v-btn>
        <v-btn
          v-if="assetType === 'image' && withOptions && value"
          color="warning"
          class="ma-2"
          outlined
          @click="editing = true"
        >
          <v-icon left v-text="'mdi-pencil'" />
          Edit
        </v-btn>
        <v-btn
          v-if="value"
          color="error"
          class="ma-2"
          outlined
          @click="file=null;$emit('input', null)"
        >
          <v-icon left v-text="'mdi-delete'" />
          Remove
        </v-btn>
      </v-col>
    </v-row>
    <asset-browser
      v-model="browse"
      :asset-type="assetType"
      :folder="folder"
    />
    <image-editor
      v-if="assetType === 'image' && value"
      v-model="editing"
      :edit="value"
      @saved="editing=false;$emit('input', $event)"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { getAsyncCurrentUser, storageRef, storageTaskState } from '../../plugins/firebase'
export default {
  name: 'AssetManager',
  props: {
    value: {
      type: [Object, String],
      default: () => ''
    },
    assetType: {
      type: String,
      default: () => 'image'
    },
    withOptions: {
      type: Boolean,
      default: () => true
    }
  },
  data: () => ({
    user: undefined,
    editing: false,
    file: undefined,
    fileCounter: 0,
    uploadTask: null,
    uploadState: null,
    uploadProgress: null,
    browse: false,
    error: null
  }),
  computed: {
    folder() {
      return `${this.assetType}s`
    },
    filename() {
      if (!this.file || !this.file.name) {
        return null
      }
      if (!this.fileCounter) {
        return this.file.name
      }
      const splitFilename = this.file.name.split('.')
      const ext = splitFilename.splice(-1, 1)[0]
      const filename = splitFilename.join('.')
      return `${filename} (${this.fileCounter}).${ext}`
    },
    acceptedExtensions() {
      return this.assetType === 'image'
        ? ['jpeg', 'jpg', 'png', 'webp', 'gif', 'svg']
        : this.assetType === 'video'
          ? ['mp4', 'ogg', 'webm', 'wmv', 'avi', 'mov', 'mkv']
          : (this.assetType === 'document' || this.assetType === 'pdf')
              ? ['pdf']
              : []
    },
    ...mapState(['organizationId'])
  },
  watch: {
    browse(val) {
      if (typeof val === 'string') {
        this.$emit('input', val)
        this.browse = false
      }
    }
  },
  methods: {
    async fileSelected(e) {
      this.error = null
      this.file = e.target.files[0]
      if (!this.filename) {
        return
      }
      const { valid } = this.validate()
      if (valid) {
        if (!this.user) {
          this.user = await getAsyncCurrentUser()
        }
        if (this.file) {
          this.fileCounter = 0
          storageRef.child(`${this.organizationId}/${this.folder}/${this.filename}`).getDownloadURL().then(this.onResolve, this.onReject)
        } else {
          this.$emit('input', '')
        }
      } else {
        this.error = `${this.capitalizeFirstLetter(this.assetType)} has to be one of the following formats: ${this.acceptedExtensions.join(', ')}`
      }
    },
    validate() {
      const ext = this.filename.split('.').reverse()[0]
      const valid = this.acceptedExtensions.includes(ext.toLowerCase())
      console.log(ext, this.acceptedExtensions)
      return { valid }
    },
    onResolve(foundURL) {
      this.fileCounter++
      storageRef.child(`${this.organizationId}/${this.folder}/${this.filename}`).getDownloadURL().then(this.onResolve, this.onReject)
    },
    onReject(error) {
      if (error.code === 'storage/object-not-found') {
        this.upload()
      }
    },
    upload() {
      if (!this.file) {
        // eslint-disable-next-line
        return console.error('no file for upload')
      }
      this.uploading = true
      this.uploadTask = storageRef.child(`${this.organizationId}/${this.folder}/${this.filename}`).put(this.file, { cacheControl: 'public,max-age=31536000' })
      this.uploadState = 'started'
      this.uploadTask.on('state_changed', this.uploadStateChanged, this.uploadFailed, this.uploadComplete)
    },
    uploadStateChanged(snapshot) {
      this.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      switch (snapshot.state) {
        case storageTaskState.PAUSED:
          this.uploadState = 'paused'
          break
        case storageTaskState.RUNNING:
          this.uploadState = 'running'
          break
      }
    },
    uploadFailed(error) {
      this.uploadState = 'error'
      alert(error)
    },
    uploadComplete() {
      this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        this.uploadState = null
        this.uploadProgress = null
        this.$emit('input', downloadURL)
      })
    }
  }
}
</script>

<style scoped lang="sass">
  .upload-button input[type=file]
    position: fixed
    top: -99999
    right: -99999
    width: 1px
    height: 1px
    text-align: right
    filter: alpha(opacity=0)
    opacity: 0
    outline: none
    cursor: inherit
    display: block
  .asset-preview--document
    position: absolute
    width: 100%
    height: 100%
    > div
      position: relative
      width: 100%
      height: 100%
      .v-icon
        font-size: 150px
      > div
        max-width: 100%
</style>
