const faker = require('faker/locale/de')
const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const locationOptions = () => {
  return {
    location: faker.address.city(),
    title: faker.company.companyName(),
    photo: faker.image.business(400, 300, true).replace('http', 'https'),
    brands: [...Array(randomIntFromInterval(1, 3))].map(() => {
      return {
        logo: faker.image.abstract(100, 100, true).replace('http', 'https')
      }
    }),
    address: faker.address.streetAddress(),
    phone: faker.phone.phoneNumber(),
    fax: faker.phone.phoneNumber(),
    email: faker.internet.email()
  }
}

export default {
  items: [...Array(3)].map(() => locationOptions())
}
