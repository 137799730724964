import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

export const firebaseConfig = {
  apiKey: 'AIzaSyBGJgQU3nuEkznf3HPWJ6J_vxwNy1Id8UY',
  authDomain: 'mammutos-7ad07.firebaseapp.com',
  databaseURL: 'https://mammutos-7ad07.firebaseio.com',
  projectId: 'mammutos-7ad07',
  storageBucket: 'mammutos-7ad07.appspot.com',
  messagingSenderId: '285415740544',
  appId: '1:285415740544:web:001cf88e6d380433508265'
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

Vue.prototype.$firebase = firebase

export const storageTaskState = firebase.storage.TaskState
export const storage = firebase.storage()
export const storageRef = storage.ref()
Vue.prototype.$storageRef = storageRef

export const db = firebase.firestore()
db.settings({ cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED })

Vue.prototype.$db = db

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
// createdAt: firebase.firestore.FieldValue.serverTimestamp(),
export const { Timestamp, GeoPoint, FieldValue } = firebase.firestore

Vue.prototype.$nowstamp = (date) => {
  if (date) {
    return Timestamp.fromDate(date)
  }
  return Timestamp.fromDate(new Date())
}

export const auth = firebase.auth()
Vue.prototype.$auth = auth

export const getAsyncCurrentUser = () => {
  let userLoaded = false
  return new Promise((resolve, reject) => {
    if (userLoaded) {
      resolve(auth.currentUser)
    }
    const unsubscribe = auth.onAuthStateChanged((user) => {
      userLoaded = true
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

Vue.prototype.$getAsyncCurrentUser = getAsyncCurrentUser

// firebase.functions().useFunctionsEmulator('http://localhost:5001')
Vue.prototype.$functions = firebase.functions()
