<template>
  <v-dialog
    v-model="pairingDialog"
    persistent
    max-width="35rem"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title class="justify-center headline text-center" style="word-break: break-word;">
        Enter the number below in the dashboard or scan the QR code.
      </v-card-title>
      <v-card-title class="justify-center font-weight-bold">
        <v-btn
          outlined
          x-large
          class="display-4"
          style="height: auto;"
        >
          {{ pairingNumber }}
        </v-btn>
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        <div ref="qart" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import QArt from 'qartjs'
export default {
  name: 'Pair',
  data: () => ({
    pairingDialog: false
  }),
  computed: {
    ...mapGetters(['pairingNumber', 'display'])
  },
  watch: {
    pairingNumber: {
      handler(pairingNumber) {
        if (pairingNumber) {
          this.pairingDialog = true
          this.$nextTick(this.displayQR)
        }
      },
      immediate: true
    }
  },
  methods: {
    displayQR() {
      if (!this.$refs.qart || !this.$refs.qart.parentNode || !this.$refs.qart.parentNode.offsetWidth) {
        setTimeout(this.displayQR, 1000)
        return
      }
      const pairingURL = `https://backend.mammutos.com/pair/${this.pairingNumber}`
      const qart = new QArt({
        value: pairingURL,
        imagePath: '/logo.png',
        filter: 'color',
        size: this.$refs.qart.parentNode.offsetWidth,
        version: 5
      })
      qart.make(this.$refs.qart)
    }
  }
}
</script>
