import Vue from 'vue'
import { kebabCase } from 'vuetify/lib/util/helpers'
import App from '@backend/App.vue'
import router from '@backend/router'
import store from '@backend/store'
import '@/plugins'
import '@backend/plugins/filters'
import '@backend/plugins/axios'
import initAgora from '@backend/plugins/agora'

// load backend components
import CardLoader from '@backend/components/CardLoader'
import ExpandCard from '@backend/components/ExpandCard'
import vuetify from '@/plugins/vuetify'
Vue.component('CardLoader', CardLoader)
Vue.component('ExpandCard', ExpandCard)

// load all core components
const requireContext = require.context('@/components/core', true, /.*\.vue$/)
requireContext.keys().forEach((relativePath) => {
  const CamelCase = relativePath.split('/').reverse()[0].split('.')[0]
  let name = kebabCase(CamelCase)
  name = name.startsWith('m') ? `${name.substring(0, 1)}-${name.substring(1)}` : name
  Vue.component(name, requireContext(relativePath).default)
})

const appendScripts = () => {
  const fjs = document.getElementsByTagName('script')[0]
  for (const src of [
    'https://deploy.userpilot.io/83fl7p8.js',
    '//code.tidio.co/fhcycerne5lgmdq1rjyqgw6h8ckd7nz2.js',
    'https://js.stripe.com/v3/'
  ]) {
    const js = document.createElement('script')
    js.async = true
    js.defer = true
    js.src = src
    fjs.parentNode.insertBefore(js, fjs)
  }
}

const initStripe = () => {
  if (!window.Stripe) {
    setTimeout(initStripe, 100)
    return
  }
  Vue.prototype.$stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
}

Vue.config.productionTip = false
const initApp = async() => {
  appendScripts()
  initStripe()
  await initAgora()
  await store.dispatch('setUserAsync')
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}
initApp()
