<template>
  <div />
</template>

<script>
export default {
  name: 'Index',
  mounted() {
    window.location.assign('https://mammutos.com/')
  }
}
</script>
