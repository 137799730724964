import Vue from 'vue'
import Router from 'vue-router'

import Redirect from '@frame/views/Redirect'
import Display from '@frame/views/Display'
import Layout from '@frame/views/Layout'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '',
      component: Redirect
    },
    {
      path: '/display/:machineId',
      component: Display,
      props: true
    },
    {
      path: '/layout/:layoutId',
      component: Layout,
      props: true
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

export default router
