<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="isFullscreen"
    max-width="600px"
    scrollable
  >
    <v-card
      flat
      min-height="80vh"
    >
      <v-card-title>
        Select {{ assetType | capitalizeFirstLetter }}
        <v-spacer />
        <v-btn
          v-if="!$vuetify.breakpoint.xsOnly"
          fab
          text
          class="mt-n2 mr-n2"
          @click="fullscreen = !fullscreen"
        >
          <v-icon>mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn
          fab
          text
          class="mt-n2 mr-n4"
          @click="$emit('input', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text flex-wrap>
        <asset-gallery
          :asset-type="assetType"
          :folder="folder"
          @select="$emit('input', $event)"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AssetBrowser',
  props: {
    assetType: {
      type: String,
      default: () => 'image'
    },
    folder: {
      type: String,
      default: () => 'images'
    },
    value: {
      type: [Boolean, String],
      default: () => false
    }
  },
  data: () => ({
    dialog: false,
    fullscreen: false,
    currentView: ''
  }),
  computed: {
    isFullscreen() {
      return this.$vuetify.breakpoint.xsOnly || this.fullscreen
    }
  },
  watch: {
    value: {
      handler() {
        this.dialog = this.value === true
      },
      immediate: true
    },
    dialog: {
      handler(val) {
        !val && this.$emit('input', false)
      },
      immediate: true
    }
  },
  methods: {
    getMComponent(layoutComponent) {
      if (layoutComponent.widgetId) {
        return this.$store.getters.MWidgetById(layoutComponent.widgetId)
      }
      if (layoutComponent.pageId) {
        return this.$store.getters.MPageById(layoutComponent.pageId)
      }
      if (layoutComponent.pluginId) {
        return this.$store.getters.MPluginById(layoutComponent.pluginId)
      }
    }
  }
}
</script>
