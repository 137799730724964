<template>
  <v-menu
    v-model="menu"
    transition="scale-transition"
    :close-on-content-click="false"
    offset-y
    min-width="290px"
  >
    <template #activator="{ on }">
      <v-text-field
        :value="value | formatDate('DD.MM.YYYY HH:mm')"
        :label="label"
        :prependi-con="prependIcon"
        outlined
        readonly
        @click.stop="tab = 0"
        v-on="on"
      />
    </template>
    <v-card
      class="m-datetime-picker"
      width="290"
      flat
    >
      <v-tabs
        v-model="tab"
        fixed-tabs
        background-color="indigo"
        dark
      >
        <v-tab>
          <v-icon>
            mdi-calendar-month-outline
          </v-icon>
        </v-tab>
        <v-tab>
          <v-icon>
            mdi-calendar-clock
          </v-icon>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-date-picker
            v-model="date"
            :min="minDate"
            :max="maxDate"
            flat
            @input="tab = 1"
          />
        </v-tab-item>
        <v-tab-item>
          <v-time-picker
            v-model="time"
            :min="date === minDate ? minTime : null"
            :max="date === maxDate ? maxTime : null"
            flat
            scrollable
            :allowed-minutes="m => m % 5 === 0"
          />
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions>
        <v-btn
          text
          @click="clear"
        >
          Clear
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="save"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'MDatetimePicker',
  props: {
    value: {
      type: [Date, String, Object],
      default: () => ''
    },
    label: {
      type: String,
      default: () => ''
    },
    prependIcon: {
      type: String,
      default: () => ''
    },
    min: {
      type: [Date, String, Object],
      default: () => ''
    },
    max: {
      type: [Date, String, Object],
      default: () => ''
    }
  },
  data: () => ({
    menu: false,
    tab: undefined,
    date: undefined,
    time: undefined,
    minDate: undefined,
    maxDate: undefined,
    minTime: undefined,
    maxTime: undefined
  }),
  watch: {
    value: {
      handler(value) {
        const dateTime = this.getMoment(value)
        if (!dateTime) {
          this.date = undefined
          this.time = undefined
          return
        }
        this.date = dateTime.format('YYYY-MM-DD')
        this.time = dateTime.format('HH:mm')
      },
      immediate: true
    },
    min: {
      handler(min) {
        if (min) {
          const dateTime = this.getMoment(min)
          this.minDate = dateTime.format('YYYY-MM-DD')
          this.minTime = dateTime.format('HH:mm')
        }
      },
      immediate: true
    },
    max: {
      handler(max) {
        if (max) {
          const dateTime = this.getMoment(max)
          this.maxDate = dateTime.format('YYYY-MM-DD')
          this.maxTime = dateTime.format('HH:mm')
        }
      },
      immediate: true
    }
  },
  methods: {
    clear() {
      this.$emit('input', undefined)
    },
    save() {
      let dateString = this.date
      if (this.time) {
        dateString += ` ${this.time}`
      }
      const dateTime = new Date(dateString)
      const timestamp = this.$nowstamp(dateTime)
      this.$emit('input', timestamp)
      this.menu = false
    }
  }
}
</script>

<style lang="sass">
  .m-datetime-picker
    .v-time-picker-title__time .v-picker__title__btn, .v-time-picker-title__time span, .v-date-picker-title
      height: 56px
</style>
