var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{staticClass:"mb-8",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{style:({ borderColor: hover ? 'black' : null }),attrs:{"outlined":""}},[_c('v-subheader',[_vm._v(" "+_vm._s(_vm.pluralLabel)+" ")]),_c('v-card-text',{staticClass:"pt-0"},[_c('m-grid-array',{attrs:{"item-photo":_vm.itemPhoto,"item-icon":_vm.itemIcon,"item-title":_vm.itemTitle,"item-subtitle":_vm.itemSubtitle,"before-delete":_vm.beforeDelete},on:{"edit-item":function($event){return _vm.edit($event)}},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_vm._t("item.icon",null,{"item":item})]}},{key:"item.photo",fn:function(ref){
var item = ref.item;
return [_vm._t("item.photo",null,{"item":item})]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._t("item.title",null,{"item":item})]}},{key:"item.subtitle",fn:function(ref){
var item = ref.item;
return [_vm._t("item.subtitle",null,{"item":item})]}}],null,true),model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.add}},[_vm._v(" Add "+_vm._s(_vm.name || 'item')+" ")])],1)],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"600px","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('validation-observer',{ref:"itemForm",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4"},[_vm._t("default",null,{"editItem":_vm.editItem})],2),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!valid},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)]}}],null,true)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }