import Vue from 'vue'
import moment from 'moment'
moment.locale('de')

const isDev = process.env.NODE_ENV === 'development'
const plainBase64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mMs/w8AAfMBeIBXwkoAAAAASUVORK5CYII='
const imageCdn = isDev
  ? 'http://localhost:5000'
  : 'https://cdn.mammutos.com'

const downloadURL = function(value) {
  if (!value) {
    return null
  }
  const { bucket, fullPath, token } = value
  let originalSrc = `https://firebasestorage.googleapis.com/v0/b/${bucket || 'mammutos-7ad07.appspot.com'}/o/${encodeURIComponent(fullPath)}?alt=media`
  if (token) {
    originalSrc += '&token=' + token
  }
  return originalSrc
}
Vue.filter('downloadURL', downloadURL)

const assetInfoFromDownloadURL = function(value) {
  if (!value) {
    return null
  }
  const re = /https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/(.*)\/o\/(.*)\?alt=media&token=(.*)/
  const [, bucket, fullPath, token] = value.match(re)
  return { bucket, fullPath: decodeURIComponent(fullPath), token }
}
Vue.filter('assetInfoFromDownloadURL', assetInfoFromDownloadURL)

const sanitizeSrc = (path) => {
  // Strip leading slash first (we'll re-add after encoding)
  path = path.replace(/^\//, '')
  if (/^https?:\/\//.test(path)) {
    // Use de/encodeURIComponent to ensure *all* characters are handled,
    // since it's being used as a path
    path = encodeURIComponent(path)
  } else {
    // Use de/encodeURI if we think the path is just a path,
    // so it leaves legal characters like '/' and '@' alone
    path = encodeURI(path).replace(/[#?:]/g, encodeURIComponent)
  }
  return path
}
const mImage = (value, options) => {
  const src = typeof value === 'object' ? value.src : value
  if (!src) {
    return plainBase64
  }

  let queryString = '?src=' + sanitizeSrc(src)
  options = options || {}
  if (typeof value !== 'string') {
    options = { ...value, ...options }
  }

  if (options.extract) {
    queryString += '&extract=' + options.extract
  }
  if (options.width || options.w) {
    queryString += '&w=' + (options.width || options.w)
  }
  if (options.height || options.h) {
    queryString += '&h=' + (options.height || options.h)
  }
  if (options.fit) {
    queryString += '&fit=' + options.fit
    if (options.bg) {
      queryString += '&bg=' + options.bg
    }
  }
  return imageCdn + queryString
}
Vue.filter('mImage', mImage)

Vue.filter('formatResolution', ({ width, height }) => {
  return `${width}x${height}`
})

Vue.filter('aspectRatio', ({ width, height }) => {
  return width / height
})

Vue.filter('dateKey', (layoutComponent) => {
  const date = layoutComponent.updatedAt || layoutComponent.createdAt
  if (date) {
    if (date.toDate) {
      return date.toDate().toJSON()
    }
    if (date._seconds && date._nanoseconds) {
      return [date._seconds, date._nanoseconds].join('')
    }
  }
  return layoutComponent.id
})

Vue.filter('formatUnixDate', (value, format = 'DD.MM.YYYY HH:mm') => {
  return moment.unix(value).format(format)
})

Vue.filter('displayPlan', (plan) => {
  return `${plan ? capitalizeFirstLetter(plan) : 'Free'} Plan`
})

Vue.filter('formatPrice', (value, currency) => {
  currency = currency || '€'
  return `${currency} ${parseFloat(value / 100).toFixed(2)}`
})

const formatDate = (value, format = 'DD.MM.YYYY HH:mm') => {
  if (!value) {
    return null
  }
  if (typeof value === 'object' && typeof value.toDate === 'function') {
    value = value.toDate()
  }
  if (`${value}`.length === 10) {
    value = value * 1000
  }
  return moment(value).format(format)
}
Vue.filter('formatDate', formatDate)

const getMoment = (value) => {
  if (!value) {
    return null
  }
  if (typeof value === 'string') {
    return moment(value)
  }
  if (Object.prototype.toString.call(value) === '[object Date]') {
    return moment(value)
  }
  if (typeof value === 'object' && typeof value.toDate === 'function') {
    return moment(value.toDate())
  }
}
Vue.filter('getMoment', getMoment)

Vue.filter('formatDuration', (value) => {
  const duration = moment.duration(value, 'seconds')
  let hours = duration.hours()
  let minutes = duration.minutes()
  let seconds = duration.seconds()
  hours = hours ? `${hours}:` : ''
  if (!minutes) {
    minutes = '00'
  }
  if (!seconds) {
    seconds = '00'
  } else if (seconds < 10) {
    seconds = `0${seconds}`
  }
  return `${hours}${minutes}:${seconds}`
})

export const capitalizeFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}
Vue.filter('capitalizeFirstLetter', capitalizeFirstLetter)

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes'
  }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / k ** i).toFixed(dm)) + ' ' + sizes[i]
}
Vue.filter('formatBytes', formatBytes)

export const getColor = (theme = {}, color) => {
  return theme[color] || color
}

export const speak = (message, voiceName = 'Google Deutsch') => {
  const speechSynthesis = window.speechSynthesis
  speechSynthesis.cancel()
  const msg = new SpeechSynthesisUtterance()
  const voice = speechSynthesis.getVoices().find(x => x.name === voiceName) || speechSynthesis.getVoices[0]
  msg.voice = voice
  msg.lang = voice.lang
  // msg.voiceURI = 'native'
  msg.voiceURI = voice.voiceURI
  msg.volume = 1
  msg.rate = 1 // 0.1 to 10
  msg.pitch = 1 // 0 to 2
  msg.text = message
  speechSynthesis.speak(msg)
}

Vue.mixin({
  methods: {
    formatDate,
    getMoment,
    capitalizeFirstLetter,
    speak,
    mImage,
    assetInfoFromDownloadURL,
    downloadURL,
    getColor
  }
})

// click outside directive
Vue.directive('m-click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // here I check that click was outside the el and his childrens
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
