<template>
  <v-menu
    v-model="menu"
    :nudge-right="40"
    transition="scale-transition"
    :close-on-content-click="false"
    offset-y
    min-width="290px"
  >
    <template #activator="{ on }">
      <v-text-field
        :value="value | formatDate('DD.MM.YYYY')"
        :label="label"
        :prepend-icon="prependIcon"
        outlined
        readonly
        v-on="on"
      />
    </template>
    <v-date-picker v-model="date" @input="menu = false" />
  </v-menu>
</template>

<script>
import moment from 'moment'
export default {
  name: 'MDateForm',
  props: {
    value: {
      type: [Date, String],
      default: () => ''
    },
    label: {
      type: String,
      default: () => ''
    },
    prependIcon: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    menu: false
  }),
  computed: {
    date: {
      get() {
        const date = (typeof this.value === 'string') ? moment(this.value) : this.value
        return date ? this.formatDate(date, 'YYYY-MM-DD') : null
      },
      set(date) {
        this.$emit('input', new Date(date))
      }
    }
  }
}
</script>
